import request from '@/core/services/api.services.request'

export function getAllCustomers(params) {
  return request({
    url: '/customer/list',
    method: 'get',
    params: params
  })
}

export function getCustomer(customer_id) {
  return request({
    url: '/customer/view/' + customer_id,
    method: 'get',
  })
}

export function getCustomerCredit(customer_id) {
  return request({
    url: '/customer/credit/get/' + customer_id,
    method: 'get',
  })
}

export function deleteCustomer(customer_id) {
  return request({
    url: '/customer/delete/' + customer_id,
    method: 'delete',
  })
}

export function changeStatus(customer_id, status) {
  return request({
    url: '/customer/change/status/' + customer_id + '/' + status,
    method: 'post',
  })
}

export function deleteAddress(address_id) {
  return request({
    url: '/customer/address/delete/' + address_id,
    method: 'delete',
  })
}

export function getOrder(customer_id) {
  return request({
    url: '/customer/order/' + customer_id,
    method: 'get',
  })
}

export function getUsers() {
  return request({
    url: '/user/creditlist',
    method: 'get',
  })
}

export function getAllUsers() {
  return request({
    url: '/all/user',
    method: 'get',
  })
}

export function getCreditView(credit_id) {
  return request({
    url: '/credit/view/' + credit_id,
    method: 'get',
  })
}

export function getUploadDocuments(credit_id) {
  return request({
    url: '/credit/documents/' + credit_id,
    method: 'get',
  })
}
export function getCustomerType() {
  return request({
    url: '/type/list',
    method: 'get',
  })
}
export function getCreditList(params) {
  return request({
    url: '/credit/list',
    method: 'get',
    params: params
  })
}
export function deleteCredit(credit_id) {
  return request({
    url: '/credit/delete/' + credit_id,
    method: 'delete',
  })
}
export function getDocumentList() {
  return request({
    url: '/document/list',
    method: 'get',
  })
}
export function searchCustomer(keyword) {
  return request({
    url: '/order/search/customer/' + keyword,
    method: 'get',
  })
}

export function getAllInvoice(customer_id) {
  return request({
    url: 'customer/invoice/list/' + customer_id,
    method: 'post',

  })
}
export function getStoreCredits(customer_id) {
  return request({
    url: '/customer/store/credits/' + customer_id,
    method: 'get',
  })
}

export function deleteStoreCredit(store_credit_id) {
  return request({
    url: '/delete/store/credits/' + store_credit_id,
    method: 'delete',
  })
}

export function getCustomerInvoices(customer_id) {
  return request({
    url: '/customer/invoice/list/' + customer_id,
    method: 'get',
  })
}


export function getInvoicesByStoreCreditId(store_credit_id) {
  return request({
    url: '/invoice/store_credit/list/' + store_credit_id,
    method: 'get',
  })
}

export function getStoreCreditBalance(customer_id) {
  return request({
    url: '/customer/credit/balance/' + customer_id,
    method: 'get',
  })
}

export function getEmailCheck(email) {
  return request({
    url: '/customer/check/email/' + email,
    method: 'get',
  })
}

export function getPhoneCheck(phone, params = null) {
  return request({
    url: '/customer/check/phone/' + phone,
    method: 'get',
    params: params
  })
}

export function addAddress() {
  return request({
    url: '/customer/address/add',
    method: 'post',
  })
}

export function getCustomerJob() {
  return request({
    url: '/customer/job/category/list',
    method: 'get',
  })
}

export function getCardDetails(customer_id) {
  return request({
    url: '/card/details/' + customer_id,
    method: 'get',
  })
}

export function cardActive(card_id) {
  return request({
    url: '/card/active/' + card_id,
    method: 'get',
  })
}

export function getYearsales(customer_id) {
  return request({
    url: '/customer/year/sales/' + customer_id,
    method: 'get',
  })
}

export function getPayments(customer_id) {
  return request({
    url: '/customer/payment/' + customer_id,
    method: 'get',
  })
}

export function getSalesoutsidelist() {
  return request({
    url: '/outside/sale/customer/list',
    method: 'get',
  })
}

export function getCreditRequiredlist() {
  return request({
    url: '/credit/require_document/list',
    method: 'get',
  })
}

export function generateCustomerId(website_id) {
  return request({
    url: '/customer/generateCustomerId/' + website_id,
    method: 'get',
  })
}

export function deleteCreditVerifiedDoc(id) {
  return request({
    url: '/credit/require_document/' + id,
    method: 'delete',
  })
}

export function GetCreditMemo(params) {
  return request({
    url: '/credit/memo/list',
    method: 'get',
    params: params
  })
}

export function getCustomerMemo(customer_id) {
  return request({
    url: '/customer/credit/memo/' + customer_id,
    method: 'get',
  })
}

export function getCustomerMemoList(customer_id, params) {
  return request({
    url: '/customer/credit/memo/list/' + customer_id,
    method: 'get',
    params: params
  })
}

export function getCustomerMemoLog(customer_id, params) {
  return request({
    url: '/customer/credit/memo/log/' + customer_id,
    method: 'get',
    params: params
  })
}
export function getRecordPayment(customer_id, params) {
  return request({
    url: '/record/paymentdetails/' + customer_id,
    method: 'get',
    params: params
  })
}

export function getCustomerRecordPayment(customer_id) {
  return request({
    url: '/record/payments/' + customer_id,
    method: 'get',
  })
}

export function getRecordPaymentLogList(recordPayment_id) {
  return request({
    url: '/record/payments/logDetails/' + recordPayment_id,
    method: 'get',
  })
}

export function getCustomerRecordPaymentLog(customer_id, params) {
  return request({
    url: '/customer/record/payments/logDetails/' + customer_id,
    method: 'get',
    params: params
  })
}

export function addDocumentType(values) {
  return request({
    url: '/document/add-type',
    method: 'post',
    data: values
  })
}

export function addCustomerCredit(formData) {
  return request({
    url: `/customer/addcredit/${formData.get('customer_id')}`,
    method: 'post',
    data: formData
  })
}
