<!-- Order -->
<template>
<div class="tax-popup">
<div slot="header" class="modal-header">
			<span class="modal-title-popup">{{label.title}}</span>
			<i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
		</div>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Orders-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Order Body-->               
                <div class="row">
                    <div class="col-xl-12">
                        <!--begin: Orders Form-->
                        <el-form ref="form" :model="form" auto-complete="false">  
                            <div class="row">
                              <div class="col-xl-8">
                                    <el-form-item label="Tax Name" prop="tax_name">
                                    <el-input v-model="form.tax_name" @input.native="capitalize" auto-complete="off" :min="0"></el-input>
                                    <span class="form-err" v-if="form.errors.has('tax_name')" >{{form.errors.get('tax_name')}}</span>
                                 </el-form-item>                                                    
                              </div>
                                <div class="col-xl-8">
                                   <el-form-item label="Amount %" prop="tax_amount">
                                    <el-input v-model="form.tax_amount" auto-complete="off" :min="0"></el-input>
                                    <span class="form-err" v-if="form.errors.has('tax_amount')" >{{form.errors.get('tax_amount')}}</span>
                                 </el-form-item>                                                    
                              </div>
                             </div>     
                               <div class="d-flex justify-content-between border-top pt-10">
                                  <a @click="addtax" class="text-white">
                                      <div class="btn btn-primary font-weight-bold text-uppercase ">
                                       {{btn_text}}
                                      </div>
                                  </a>
                                </div>              
                        </el-form>
                        <!--end: Orders Form-->
                    </div>
                </div>
                <!--end: Orders Body-->
            </div>
        </div>
        <!--end: Orders-->
    </div>
</div>
</template>

<script>
import Form from '@/core/services/api.form.services';
import { globalEvents } from '@/utils/globalEvents';

export default {
  name: 'add-order',
  props: {
    // product_id: {
    //           type: Number,                           	
	  //   },
  label:{
	      type: Object,
	      default() {
	        return {button:'Create',title:'Add Tax'}
	      }
	    }
  },
    data() {
    return {
      loading:false,
      error: null,
      btn_text : 'Add',
      form : new Form({ 
          tax_name:null,  
          tax_amount:null, 
          //product_id:null      
              
      }),       
      credit_list: [],     
       
     api_url :'tax/create/',
    }
  },
  components: {},
  mounted() {
   
    this.form.product_id = this.product_id
    console.log(this.product_id);
  },

  methods: {
     closeForm() {
	      	if (!this.form.submiting) {
	        	globalEvents.$emit('AddTax', false)
	        	this.$emit('close')
	      	}
	    },  
     addtax: function() {                
      this.loading=true;
       this.api_url = 'tax/create'
       this.form.post(this.api_url).then((response) => {         
        if(response.status){
          	globalEvents.$emit('AddTax', response.data)
	        	this.$emit('close')
            //this.$router.push({ path: '/customer/view/' })
            this.loading=false;
            //this.$showResponse('success', response.message);
        }
      })
    }, 
   
  },
  
  created(){
    //console.log(this.inventory_id);
  
    
    
  },
  
}
</script>

