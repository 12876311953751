<template>
 <div>
   <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"><i class="flaticon-interface-9" aria-hidden="true"></i>Record Payment</span>
      <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
   </div>
   <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0">
         <!--begin: Employees-->
         <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
            <!--begin: Employees Body-->
            <div class="row justify-content-center ">
               <div class="col-xl-12">
                  <el-checkbox 
                    v-model="form.checked" 
                    @change="changeDueAmount()"
                    class="mb-5 payment_checked" 
                    v-if="form.use_over_payment_amount >0"
                    >
                    Use Your <b>${{form.use_over_payment_amount}} Record Pay Overpayment</b>
                  </el-checkbox>
                  <el-form :model="form"  ref="form" label-position="left" label-width="150px" :disabled="form.submiting">
                     <el-form-item label="Payment Date" prop="status">
                        <el-form-item prop="payment_date" style="margin-bottom:0px;">
                        <el-date-picker  v-model="form.payment_date" placeholder="DD-MM-YYYY" type="date" format="dd MMM yyyy" style="width:100%;" > </el-date-picker>
                        <span class="form-err" v-if="form.errors.has('payment_date')" >{{form.errors.get('payment_date')}}</span>
                        </el-form-item>
                     </el-form-item>
                     <el-form-item label="Amount" prop="due_amount" v-if="payment_status == false">                       
                       <el-input                        
                          prefix-icon="fas fa-dollar-sign"
                          v-model="form.due_amount" @change="checkAmount(form.due_amount)">
                        </el-input>
                        <span class="form-err" v-if="form.errors.has('due_amount')" >{{form.errors.get('due_amount')}}</span>
                     </el-form-item>
                      <el-form-item label="Payment Method" prop="payment_method" v-if="payment_status == false">
                        <el-select clearable filterable v-model="form.payment_method" placeholder="Select Payment Method"  style="width:100%" :allow-create="true" @clear="PaymentClear" @change="paymentMethod()">
                           <el-option v-for="(method,index) in payment_methods" 
                           :key="index" 
                           :label="method.method_name && method.method_name == 'Term'?method.method_name + ' - $'+form.term_limit:method.method_name && method.method_name == 'Credit Memo'?method.method_name + ' - $'+credit_memo:method.method_name" 
                           :value="method.method_name" :disabled="method.method_name == 'Term'?term_condition == 1 : false || method.method_name == 'Credit Memo' ? credit_memo <= 0 : false">
                           </el-option>
                        </el-select>
                        <!-- || method.method_name == 'Credit Memo'?form.due_amount >credit_memo: false -->
                        <span v-if="form.payment_method == 'Term'">Term Limit: $ {{form.term_limit}}</span>
                        <span v-if="form.payment_method == 'Credit Memo'">Credit Memo: $ {{credit_memo}}</span>
                        <span class="form-err" v-if="form.errors.has('payment_method')" >{{form.errors.get('payment_method')}}</span>
                     </el-form-item>
                      <el-form-item label="Term overwrite By" prop="term_overwrite" v-if="overwrite_condition == 1">                       
                       <el-input      
                          v-model="form.term_overwrite"  :disabled="form.payment_method == 'Bank' || form.payment_method == 'Cheque' || form.payment_method == 'Email' || form.payment_method == 'Credit Card'">
                        </el-input>                        
                        <span class="form-err" v-if="form.errors.has('term_overwrite')" >{{form.errors.get('term_overwrite')}}</span>
                     </el-form-item>
                    
                     <!-- <el-form-item label="Bank A/C No" prop="bank_acount" v-if="form.payment_method == 'Bank'">                       
                       <el-input      
                          v-model="form.bank_acount_no">
                        </el-input>                        
                        <span class="form-err" v-if="form.errors.has('bank_acount_no')" >{{form.errors.get('bank_acount_no')}}</span>
                     </el-form-item> -->
                      <el-form-item label="Cheque No" prop="cheque_no" v-if="form.payment_method == 'Cheque'" >                       
                       <el-input      
                          v-model="form.cheque_no">
                        </el-input>                        
                        <span class="form-err" v-if="form.errors.has('cheque_no')" >{{form.errors.get('cheque_no')}}</span>
                     </el-form-item>
                      <el-form-item label="Verified By" prop="verified_by" >                       
                       <el-input      
                          v-model="form.verified_by" @input.native="capitalize">
                        </el-input>                        
                        <span class="form-err" v-if="form.errors.has('verified_by')" >{{form.errors.get('verified_by')}}</span>
                     </el-form-item> 
                     <el-form-item label="Notes" prop="notes">
                        <el-input type="textarea" :rows="4"  v-model="form.notes"></el-input>
                        <span class="form-err" v-if="form.errors.has('notes')" >{{form.errors.get('notes')}}</span>
                     </el-form-item>
                     
                  </el-form>
                   <div style="text-align:end" class="mt-3">
                          <el-button @click.prevent="closeForm()" type="warning">Back</el-button> 
                          <el-button type="primary" @click.prevent="submitForm" :icon="form.submiting?'el-icon-loading':''" class="btn btn-sm btn-primary font-weight-bold text-uppercase">Submit Payment</el-button>                          
                    </div>
                  <!--end: Employees Form-->
               </div>
            </div>
            <!--end: Employees Body-->
         </div>
      </div>
      <!--end: Employees-->
   </div>
</div>
</template>

<script>
import Form from '@/core/services/api.form.services';
import { globalEvents } from '@/utils/globalEvents';
import {getPaymentMethods} from "@/api/invoice";
import payNow from '@/components/Payment/payNow';
import {getCustomerMemo,getCustomerRecordPayment} from "@/api/customer";
import JwtService from "@/core/services/jwt.service";
export default {
  name: 'create-user',
   props: {
    invoice: {
      type: Object
    },
    Credit: {
      type: Object
    },
   
  },
  data() {
    return {
      loading:false,
      form: new Form({
        payment_date: new Date(),
        due_amount: null,
        payment_method: null,
        notes: null,
        invoice_id: null,
        term_overwrite:null,
        term_limit :0,
        bank_acount_no:null,
        cheque_no:null,
        verified_by:null,
        over_payment_amount:0,
        use_over_payment_amount : 0,
        checked:false,   
        invoice_amount : null,    
      }),
      credit_memo:'0.00',
      invoiceAmount : 0,
      term_condition:1,
      overwrite_condition:0,
      payment_methods:[],
      role_id : null,
      api_url: '/invoice/record/payment',
      response_msg: 'Payment has been successfull',
      payment_status : false,
    }
  },
  components: {
  },
  
  methods: {
    reset() {
      this.form.reset()
      this.$refs['form'].resetFields()
    },
    changeDueAmount(){
      if(this.form.checked == false){
        this.form.due_amount = this.form.invoice_amount
          this.payment_status = false
      }else{
         let total = parseFloat(this.form.invoice_amount - this.form.over_payment_amount).toFixed(2);          
         this.form.due_amount = total >0 ? total: 0  
         if(total < 0){
          this.payment_status = true
         }
      }
    },
     paymentMethod(){
      if(this.form.payment_method == 'Term'){      
         if(this.form.due_amount > this.form.term_limit){
            this.overwrite_condition = 1
        }  
      }else{
        this.overwrite_condition = 0;
        this.form.term_overwrite = '';
      }
    },
    closeForm() {
      if (!this.form.submiting) {
        this.$emit('close')
      }
        this.$modal.show(payNow, {invoice:this.invoice, Credit:this.Credit}, {
                draggable: true,
                    transition: 'pop-out',
                    height: 'auto',
                    clickToClose: true,
                    adaptive: true,
                    width: '450px'
                });
    },
    TermOverwrite(){
        if(this.form.term_overwrite){
          this.term_condition = 0;
        }
    },
    PaymentClear(){
      this.form.payment_method = '';
    },
    checkAmount(amount){     
      this.form.errors.add("due_amount", "");
      let due_amount = parseFloat(amount).toFixed(2).match(/^-?[0-9]*[.][0-9]+$/);       
      this.form.payment_method = ''; 
      if(due_amount != null){
         if(parseFloat(this.invoiceAmount) <= due_amount[0] ){   
            this.form.due_amount = this.invoice.balance_amount;
            this.form.errors.add("due_amount", "Please Enter max Amount $ "+ this.invoice.balance_amount +" Only");
         }else{
            this.form.due_amount = parseFloat(due_amount).toFixed(2);
            this.overwrite_condition = 0;
            if(this.term_condition == 1){
              if(this.form.term_limit >= due_amount[0]){               
                  this.term_condition = 0
                  this.overwrite_condition = 0;
              } 
            }
         }

      }else{
         this.form.due_amount = ''
         this.form.errors.add("due_amount", "Please Enter Amount Only");
      }
     
    },
    submitForm() {
      this.loading=true;
      this.form.payment_date = this.$formatDate(this.form.payment_date, 'YYYY-MM-DD');
      this.form.post(this.api_url).then((response) => {
        this.$message({
          message: this.response_msg,
          type: 'success'
        })
        globalEvents.$emit('invoiceAdded', response.data)
        this.$emit('close')
        this.loading=false
      }).catch((error) => { 
        console.log(error);
        this.loading=false
      })
    },
    getPaymentMethods(){
      this.loading = true
      getPaymentMethods().then(response => {
          this.payment_methods = response.data.data
          this.loading = false
      });
    },
     getCustomerMemo(){
       this.loading = true
      getCustomerMemo(this.invoice.customer_id).then(response => {      
        this.credit_memo = response.data.data.balance;     
        this.loading = false
      });
    },
  },
  created() {
    this.getPaymentMethods();
    this.getCustomerMemo();
    if (JwtService.getAttribute()) {
      let updatedUserdata = JSON.parse(JwtService.getAttribute());     
      this.role_id = updatedUserdata.role_id;
       
      //this.form.credit_access = updatedUserdata.first_name+' '+updatedUserdata.last_name
    }
    if(this.invoice) {
      this.form.invoice_id = this.invoice.id
      this.form.due_amount = this.invoice.balance_amount
      this.invoiceAmount = this.invoice.balance_amount;
      this.form.invoice_amount = this.invoice.balance_amount
    } 
    if(this.Credit){ 
      this.term_condition = 0;
        this.form.term_limit = this.Credit.available_credit;
         if(this.form.term_limit == 0){
         this.term_condition = 1;
         this.form.term_limit = '0.00'
        }
        
    }
    getCustomerRecordPayment(this.invoice.customer_id).then(response => {   
        this.form.checked = false        
        this.form.over_payment_amount = response.data.data.balance  
        this.form.use_over_payment_amount = response.data.data.balance   
         let total = parseFloat(this.form.invoice_amount - this.form.over_payment_amount).toFixed(2);
         this.form.use_over_payment_amount =  total > 0 ? parseFloat(this.form.over_payment_amount).toFixed(2) : parseFloat(this.form.invoice_amount).toFixed(2) 
         this.form.due_amount = total >0 ? total: 0  
         if(total < 0){
          this.payment_status = true
         }     
         if(this.form.over_payment_amount > 0){
           this.form.checked = true
         }   
        this.loading = false
      });
       
  }
}
</script>

<style>
.el-checkbox__input.is-checked+.el-checkbox__label{
  color: black;
}
</style>