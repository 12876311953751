<template>
 <div>
   <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"><i class="flaticon-interface-9" aria-hidden="true"></i>Record a Payment for this invoice</span>
      <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
   </div>
   <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0">
         <!--begin: Employees-->
         <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
            <!--begin: Employees Body-->
            <div class="row justify-content-center ">
               <div class="col-xl-12">  
                     <div class="card-input">
                        <label for="cardNumber" class="card-input__label">Amount</label>                        
                         <el-input 
                                              
                          prefix-icon="fas fa-dollar-sign"
                          v-model="form.due_amount"  @change="checkAmount(form.due_amount)">
                        </el-input>
                         <span class="form-err" v-if="form.errors.has('due_amount')" >{{form.errors.get('due_amount')}}</span>                      
                      </div>         
                      <div class="card-input">
                        <label for="cardNumber" class="card-input__label">Card Number</label>
                        <input type="text" id="cardNumber" class="card-input__input" v-mask="generateCardNumberMask" v-model="form.cardNumber" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardNumber" autocomplete="off">
                        <span class="form-err" v-if="form.errors.has('cardNumber')" >* {{form.errors.get('cardNumber')}}</span>
                      </div>
                      <div class="card-input">
                        <label for="cardName" class="card-input__label">Card Holders</label>
                        <input type="text" id="cardName" class="card-input__input" v-model="form.cardName" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardName" autocomplete="off">
                        <span class="form-err" v-if="form.errors.has('cardName')" >* {{form.errors.get('cardName')}}</span>
                      </div>
                      <div class="card-form__row">
                        <div class="card-form__col">
                          <div class="card-form__group">
                            <label for="cardMonth" class="card-input__label">Expiration Date</label>
                            <select class="card-input__input -select" id="cardMonth" v-model="form.cardMonth" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardDate">
                              <option value="" disabled selected>Month</option>
                              <option v-bind:value="n < 10 ? '0' + n : n" v-for="n in 12" v-bind:disabled="n < minCardMonth" v-bind:key="n">
                                  {{10 >n ? '0' + n : n}}
                              </option>
                            </select>
                             
                            <select class="card-input__input -select" id="cardYear" v-model="form.cardYear" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardDate" @change="validYear()">
                              <option value="" disabled selected>Year</option>
                              <option v-bind:value="$index + minCardYear" v-for="(n, $index) in 12" v-bind:key="n">
                                  {{$index + minCardYear}}
                              </option>
                            </select>
                          </div>
                          <span class="form-err" v-if="error_year">*{{error_year}}</span>
                         <span class="form-err" v-if="form.errors.has('cardMonth')" >* Expires month required</span>
                          <span class="form-err" v-if="form.errors.has('cardYear')" >* Expires year required</span>
                        </div>
                        <div class="card-form__col -cvv">
                          <div class="card-input">
                            <label for="cardCvv" class="card-input__label">CVV</label>
                            <input type="text" class="card-input__input" id="cardCvv" v-mask="'####'" maxlength="4" v-model="form.cardCvv" v-on:focus="flipCard(true)" v-on:blur="flipCard(false)" autocomplete="off">
                          </div>
                          <span class="form-err" v-if="form.errors.has('cardCvv')" >* Card CVV required</span>
                        </div>
                      
                      </div>
                      <el-checkbox v-model="form.checked">Save this card for future payments</el-checkbox>

                      
                        <div style="text-align:end" class="mt-3" v-loading="password_loading">
                          <el-button @click.prevent="closeForm()" type="warning">Back</el-button> 
                          <el-button type="primary" @click.prevent="submitCard" :icon="form.submiting?'el-icon-loading':''" class="btn btn-sm btn-primary font-weight-bold text-uppercase" >Submit Payment</el-button>                          
                        </div>       
               </div>
            </div>
            <!--end: Employees Body-->
         </div>
      </div>
      <!--end: Employees-->
   </div>
</div>
</template>
<script src="https://js.stripe.com/v3/"></script>
<script>
import Form from '@/core/services/api.form.services';
import { globalEvents } from '@/utils/globalEvents';
import {getPaymentMethods} from "@/api/invoice";
import {checkPayment} from "@/api/payment";
import payNow from '@/components/DirectPayment/payNow';
export default {
  name: 'create-user',
   props: {
    invoice: {
      type: Object
    },
    customer_id:{
    type:Number
   },
   Credit: {
      type: Object
    },
    formData: {
      type: Object
    },
  },
  data() {
    return {
      loading:false,
      password_loading:false,
      form: new Form({
        payment_date: new Date(),
        due_amount: null,
        payment_method: null,
        notes: null,
        invoice_id: null,
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
        checked:false,       
        stripeToken:null,
        payment_password:null,
        customer_id : null
      }),
      
      minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
      payment_methods:[],
      error_year:'',
      //api_url: '/invoice/record/payment',
      api_url : '/direct/payment/card',
      response_msg: 'Payment has been successfull',
      payment_status : 0
    }
  },
  components: {
  },
  computed: {
    getCardType () {
      let number = this.form.cardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      return "visa"; // default type
    },
		generateCardNumberMask () {
			return this.getCardType === "amex" ? this.amexCardMask : this.otherCardMask;
    },
    minCardMonth () {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    }
  },
  methods: {
      flipCard (status) {
      this.isCardFlipped = status;
    },
    validYear(){
      var today = new Date();
      var someday = new Date();
      this.error_year = '';
      someday.setFullYear(this.form.cardYear, this.form.cardMonth, 1);
          if (someday < today) {
            this.error_year = 'Please select a valid expiry date'
            this.form.cardYear='';            
          }   
    },
    focusInput (e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
      }
    },
     checkAmount(amount){
     this.form.errors.add("pay_amount", "");
      let due_amount = parseFloat(amount).toFixed(2).match(/^-?[0-9]*[.][0-9]+$/);
      
      if(this.invoice.balance_amount >= this.form.due_amount ){
       if(due_amount != null){
        this.form.due_amount = parseFloat(due_amount).toFixed(2);
      }else{
        this.form.due_amount = ''
        this.form.errors.add("due_amount", "Please Enter Amount Only");
      } 
      }else{
        this.form.due_amount = this.invoice.balance_amount
        this.form.errors.add("due_amount", "Please Enter Max Amount Only");
      }         
    },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    },
    reset() {
      this.form.reset()
      this.$refs['form'].resetFields()
    },

     updateValue() {
       
             let amount = this.form.due_amount.replace(/^\d{1,10}(\.\d{1,4})?$/)

             this.form.due_amount = amount;
         },
    
    closeForm() {
       if (!this.form.submiting) {
        this.$emit('close')
      }
       this.$modal.show(payNow, {invoice:this.invoice,customer_id:this.customer_id,Credit:this.Credit, formData:this.formData}, {
                draggable: true,
                    transition: 'pop-out',
                    height: 'auto',
                    clickToClose: true,
                    adaptive: true,
                    width: '450px'
                });
     
    },
    paymentPassword(){
     
      checkPayment(this.form.payment_password).then(response => {
          this.password_loading = true
            console.log(response.data.status);
          
           if (response.data.status === true) {
             this.payment_status = 1;
             console.log(this.payment_status);
             this.form.errors.add('payment_password' ,'') 
             this.password_loading = false
           }else{
             this.payment_status = 0;    
             this.form.errors.add('payment_password' ,response.data.message) 
             this.password_loading = false
           }
           console.log(this.payment_status);
      });
    },
     submitCard() { 
            this.loading = true;
            this.error_year = '';          
            this.form.post(this.api_url).then((response) => {                
                if (response.status == true) {                
                this.createorder(response.data.id);                
                this.$emit('close')
                this.loading=false
                    
                }else{
                    //this.form.errors.add('payment_password' ,response.message) 
                     this.$message({
                    message: response.message,
                    type: 'error'
                  })
                  this.loading=false
                }
            }).catch(error => {
                console.log(error);
                this.loading = false;
            })
    },
    createorder(paymentId){
      this.formData.paymentID = paymentId;
            this.api_urls = 'order/create/direct'            
            this.formData
            .post(this.api_urls)
            .then((response) => {
              if (response.status) {
                this.$router.push({
                  name: "order",
                });
                this.loading = false;
                this.$showResponse("success", response.message);
              }
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });         
       
    },
    getPaymentMethods(){
      this.loading = true
      getPaymentMethods().then(response => {
          this.payment_methods = response.data.data
          this.loading = false
      });
    }
  },
  created() {
    this.getPaymentMethods();
    this.form.customer_id = this.customer_id;
    if(this.invoice.id) {
      this.form.invoice_id = this.invoice.id
      this.form.due_amount = this.invoice.balance_amount
    }
  }
}
</script>
<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap");

body {
  background: #ddeefc;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}
* {
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}
.wrapper {
  min-height: 100vh;
  display: flex;
  padding: 50px 15px;
  @media screen and (max-width: 700px), (max-height: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }

  &__inner {
    background: #fff;
    // box-shadow: 3px 13px 30px 0px rgba(21, 34, 67, 0.2);
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    padding: 35px;
    //padding-top: 180px;

    @media screen and (max-width: 480px) {
      padding: 25px;
      padding-top: 165px;
    }
    @media screen and (max-width: 360px) {
      padding: 15px;
      padding-top: 165px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 150px;
      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: #2364d2;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;

  @media screen and (max-width: 480px) {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }

  @media screen and (max-width: 360px) {
    height: 180px;
  }

  &.-active {
    .card-item__side {
      &.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
          rotateZ(0deg);
      }
      &.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
        // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
      }
    }
  }

  &__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    &.-active {
      opacity: 1;
    }
  }

  &__side {
    border-radius: 15px;
    overflow: hidden;
    // box-shadow: 3px 13px 30px 0px rgba(11, 19, 41, 0.5);
    box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;

    &.-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
      z-index: 2;
      padding: 0;
      // background-color: #2364d2;
      // background-image: linear-gradient(
      //   43deg,
      //   #4158d0 0%,
      //   #8555c7 46%,
      //   #2364d2 100%
      // );
      height: 100%;

      .card-item__cover {
        transform: rotateY(-180deg)
      }
    }
  }
  &__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__cover {
    height: 100%;
    background-color: #1c1d27;
    position: absolute;
    height: 100%;
    background-color: #1c1d27;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(6, 2, 29, 0.45);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }

  &__chip {
    width: 60px;
    @media screen and (max-width: 480px) {
      width: 50px;
    }
    @media screen and (max-width: 360px) {
      width: 40px;
    }
  }

  &__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 40px;
      max-width: 90px;
    }
    @media screen and (max-width: 360px) {
      height: 30px;
    }
  }

  &__typeImg {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    object-position: top right;
  }

  &__info {
    color: #fff;
    width: 100%;
    max-width: calc(100% - 85px);
    padding: 10px 15px;
    font-weight: 500;
    display: block;

    cursor: pointer;

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  &__holder {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 6px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    font-family: "Source Code Pro", monospace;
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
    user-select: none;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  &__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
  }

  &__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    font-size: 27px;
    margin-bottom: 35px;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 21px;
      margin-bottom: 15px;
      padding: 10px 10px;
    }

    @media screen and (max-width: 360px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
  }

  &__numberItem {
    width: 16px;
    display: inline-block;
    &.-active {
      width: 30px;
    }

    @media screen and (max-width: 480px) {
      width: 13px;

      &.-active {
        width: 16px;
      }
    }

    @media screen and (max-width: 360px) {
      width: 12px;

      &.-active {
        width: 8px;
      }
    }
  }

  &__content {
    color: #fff;
    display: flex;
    align-items: flex-start;
  }

  &__date {
    flex-wrap: wrap;
    font-size: 18px;
    margin-left: auto;
    padding: 10px;
    display: inline-flex;
    width: 80px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__dateItem {
    position: relative;
    span {
      width: 22px;
      display: inline-block;
    }
  }

  &__dateTitle {
    opacity: 0.7;
    font-size: 13px;
    padding-bottom: 6px;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  &__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 360px) {
      height: 40px;
      margin-top: 10px;
    }
  }

  &__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 15px;
    .card-item__type {
      opacity: 0.7;
    }

    @media screen and (max-width: 360px) {
      padding: 10px 15px;
    }
  }
  &__cvvTitle {
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }
  &__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

    @media screen and (max-width: 480px) {
      height: 40px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }
}

.card-list {
  margin-bottom: -130px;

  @media screen and (max-width: 480px) {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 20px;
  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #1a3b5d;
    width: 100%;
    display: block;
    user-select: none;
  }
  &__input {
    width: 100%;
   // height: 50px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;
    font-family: "Source Sans Pro", sans-serif;

    &:hover,
    &:focus {
      border-color: #3d9cff;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }
    &.-select {
      -webkit-appearance: none;
      background-image: url('https://stagingurl.tk/ctshub_api/public/images/card/down_arrow.png');
      background-size: 12px;
      background-position: 90% center;
      background-repeat: no-repeat;
      padding-right: 30px;
    }
  }
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}
.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}
.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}



.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all .3s ease-in-out;

  @media screen and (min-width: 500px) {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }

  @media screen and (max-width: 700px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;

    &:active {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }
}
</style>