<template>   
<div >
    <div slot="header" class="modal-header popup-header">
          <span class="modal-title-popup"><i class="fa fa-user-circle" aria-hidden="true"></i>{{label.title}}</span>
          <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
      </div>
      <div class="card card-custom" v-loading="loading">
        <div class="card-body  p-0">
            <!--begin: Orders-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Order Body-->
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                        <!--begin: Orders Form-->
                        <el-form ref="form" :model="form" auto-complete="false" style="padding:0px !important">  
                          <el-row>                             
                             <el-col :span="24">
                               <el-form-item label="Term Due Days" >
                                 <el-input v-model="form.term_name"  onkeypress="return event.keyCode>=48&&event.keyCode<=57"
                                 @input.native="capitalize" auto-complete="nope" :class="form.errors.has('term_name') ? 'error ':''"></el-input>
                               </el-form-item>
                                <span class="form-err" v-if="form.errors.has('term_name')" >{{form.errors.get('term_name')}}</span>
                             </el-col>
                              <el-col :span="24">
                                <div class="row">
                                  <div class="col-sm-12 ">
                                    <button class="btn btn-sm btn-primary font-weight-bold text-uppercase" @click.prevent="addType()">{{btn_text}}</button>                
                                  </div>
                                </div>  
                              </el-col>

                          </el-row>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Form from '@/core/services/api.form.services';
import { globalEvents } from '@/utils/globalEvents';
export default {
    name: 'add-term',
  props: {
    
  label:{
	      type: Object,
	      default() {
	        return {button:'Create',title:'Add Term'}
	      }
	    }
  },
    data() {
    return {
        loading:false,
        error: null,
        btn_text : 'Save',
        form : new Form({      
        term_name:null,       
           
      }), 

    }
    },
    methods: {
     closeForm() {
     this.$emit('close')
	     
        },

         addType: function() {                
      this.loading=true;
       this.api_url = 'order/term/create'
       this.form.post(this.api_url).then((response) => {
        if(response.status){
            globalEvents.$emit('TermAdded', response.data)
	          this.$emit('close')           
            this.loading=false;
            //this.$showResponse('success', response.message);
        }
      }).catch((error) => {
				  this.loading = false;
	        	console.log(error)
	      	})
    }, 
    }

}
</script>