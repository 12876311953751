<template>
 <div>
   <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"><i class="flaticon-interface-9" aria-hidden="true"></i>Record a Payment for this invoice</span>
      <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
   </div>
   <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0">
         <!--begin: Employees-->
         <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
            <!--begin: Employees Body-->
            <div class="row justify-content-center ">
               <div class="col-xl-12">  
                      <!-- <el-switch v-model="form.payment_type"  style="margin-top:-23px" 
                      v-if="split_staus === true"
                       active-value="split"
                        inactive-value="single" active-text="Split Payments"
                        @change="splitAmount(form.payment_type)">
                       </el-switch> -->
                     <div class="card-input">
                        <label for="amount" class="card-input__label">Amount </label>                        
                         <el-input                        
                          prefix-icon="fas fa-dollar-sign"
                          :disabled="form.payment_type == 'split'"
                          v-model="form.due_amount" @change="checkAmount(form.due_amount)">
                        </el-input>
                      </div> 
                      <div class="card-input mt-2" v-if="form.payment_type == 'split'">
                        <label for="term_limit" class="card-input__label">Term Amount (Term limit: $ {{form.term_limit}})</label>                        
                         <el-input 
                         disabled                      
                          prefix-icon="fas fa-dollar-sign"
                          v-model="form.term_amount">
                        </el-input>
                      </div> 
                      <div class="card-input mt-2" v-if="form.payment_type == 'split'">
                        <label for="due_amount" class="card-input__label">Pay Amount</label>                        
                         <el-input                        
                          prefix-icon="fas fa-dollar-sign"
                          v-model="form.pay_amount">
                        </el-input>
                      </div> 
                        
                     <div class="card-input mt-3">
                        <label for="cardNumber" class="card-input__label">Select Payment Method</label>
                      <template>
                        <el-row class="p-3 card_details">                          
                          <el-col :span="15">{{card_details.brand}}**** **** **** {{card_details.last_4}}</el-col>
                          <el-col :span="8" :offset="1">Expires : {{card_details.cardMonth}}/{{card_details.cardYear}}</el-col>                                                      
                          
                          <el-row class="mt-9">
                          <el-col :span="24" >Card Owner :{{card_details.cardName}}                             
                          </el-col> 
                          </el-row>
                        </el-row>
                        <el-row>
                          <el-col :span="24" class="use_dif p-3" >                           
                           <span @click.prevent="DifferentCard()"> Use a Different Credit Card </span>
                          </el-col>
                        </el-row>                          
                          <div class="card-input mt-3">
                          <label for="payment_password" class="card-input__label">Payment Password *</label>                        
                          <el-input                        
                            type="password"
                            v-model="form.payment_password"
                            @change="paymentPassword()"
                            >
                        </el-input>
                         <el-row> 
                            <span class="form-err" v-if="form.errors.has('payment_password')" v-loading="password_loading">{{form.errors.get('payment_password')}}</span>
                          </el-row>
                      </div> 
                      </template> 
                     </div>  
                        <div style="text-align:end" class="mt-5" v-loading="password_loading">
                          <el-button @click.prevent="closeForm()" type="warning">Back</el-button> 
                          <el-button type="primary" @click.prevent="submitCard" :icon="form.submiting?'el-icon-loading':''" class="btn btn-sm btn-primary font-weight-bold text-uppercase" :disabled="payment_status==0">Submit Payment</el-button>                          
                        </div>       
               </div>
            </div>
            <!--end: Employees Body-->
         </div>
      </div>
      <!--end: Employees-->
   </div>
</div>
</template>

<script>

import Form from '@/core/services/api.form.services';
//import { globalEvents } from '@/utils/globalEvents';
import {getPaymentMethods} from "@/api/invoice";
import payNow from '@/components/DirectPayment/payNow';

//import cardPayment from '@/components/Payment/cardPayment';
import cardList from '@/components/DirectPayment/cardList';
import {checkPayment} from "@/api/payment";
export default {
  name: 'create-user',
   props: {
    invoice: {
      type: Object
    },
   
    card: {
      type: Object,
      required: true,
    },
     Credit: {
      type: Object
    },
     customer_id:{
        type:Number
      },
    formData: {
      type: Object
    },
   
  },
  data() {
    return {
      loading:false,
      password_loading:false,
      form: new Form({
        payment_date: new Date(),
        due_amount: null,
        payment_method: null,
        notes: null,
        invoice_id: null,
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
        checked:false,
        stripeToken:null,
        payment_password:null,
        payment_type:null,
        term_limit:0,
        pay_amount:0,
        term_amount:0,
        customer_id:null
      }),     
      card_details:null,
      minCardYear: new Date().getFullYear(),
      split_staus : false,
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
      payment_methods:[],
      //api_url: '/invoice/record/payment',
      api_url : '/direct/payment/card',
      response_msg: 'Payment has been successfull',
      payment_status : 0
    }
  },
  components: {
    
  },
  
  computed: {
   
    minCardMonth () {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    }
  },
  methods: {
     splitAmount(type){
       if(type == 'split'){
         this.form.term_amount = this.form.term_limit;
         this.form.pay_amount = parseFloat(this.form.due_amount - this.form.term_amount).toFixed(2)
       }
     },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    },
    reset() {
      this.form.reset()
      this.$refs['form'].resetFields()
    },
     checkAmount(amount){
      
     this.form.errors.add("due_amount", "");
      let due_amount = parseFloat(amount).toFixed(2).match(/^-?[0-9]*[.][0-9]+$/);
       
      if(this.invoice.balance_amount >= this.form.due_amount ){
       if(due_amount != null){
        this.form.due_amount = parseFloat(due_amount).toFixed(2);
      }else{
        this.form.due_amount = ''
        this.form.errors.add("due_amount", "Please Enter Amount Only");
      } 
      }else{
        this.form.due_amount = parseFloat(this.invoice.balance_amount).toFixed(2);
        this.form.errors.add("due_amount", "Please Enter Max Amount Only");
      }        
    },
    
    closeForm() {
       this.$emit('close')
       this.$modal.show(payNow, {invoice:this.invoice,Credit:this.Credit,customer_id:this.customer_id,formData:this.formData}, {
                draggable: true,
                    transition: 'pop-out',
                    height: 'auto',
                    clickToClose: true,
                    adaptive: true,
                    width: '450px'
                });
     
    },
    DifferentCard(){
      this.$emit('close')
      this.$modal.show(cardList, {invoice:this.invoice,formData:this.formData}, {
                draggable: true,
                    transition: 'pop-out',
                    height: 'auto',
                    clickToClose: true,
                    adaptive: true,
                    width: '450px'
                });
    },
     paymentPassword(){
     
      checkPayment(this.form.payment_password).then(response => {
          this.password_loading = true
          
           if (response.data.status === true) {
             this.payment_status = 1;             
             this.form.errors.add('payment_password' ,'') 
             this.password_loading = false
           }else{
             this.payment_status = 0;    
             this.form.errors.add('payment_password' ,response.data.message) 
             this.password_loading = false
           }           
      });
    },
     submitCard() { 
      this.loading = true;       
          if(this.form.payment_type == 'split'){
              let url = 'split/payment'
             this.form.post(url).then((response) => {                
                if (response.status == true) {
                this.createorder(response.data.id); 
                this.$emit('close')
                this.loading=false                    
                }else{
                  this.form.errors.add('payment_password' ,response.message) 
                     this.$message({
                    message: response.message,
                    type: 'error'
                  })
                  this.loading=false
                }
            }).catch(error => {
                console.log(error);
                this.loading = false;
            })

          } else{  
            this.form.post(this.api_url).then((response) => {                
                if (response.status == true) {                   
                this.createorder(response.data.id);
                this.$emit('close')
                this.loading=false                    
                }else{
                  this.form.errors.add('payment_password' ,response.message) 
                     this.$message({
                    message: response.message,
                    type: 'error'
                  })
                  this.loading=false
                }
            }).catch(error => {
                console.log(error);
                this.loading = false;
            })

          }
    },

    createorder(paymentId){
      this.formData.paymentID = paymentId;
            this.api_urls = 'order/create/direct'            
            this.formData
            .post(this.api_urls)
            .then((response) => {
              if (response.status) {
                this.$router.push({
                  name: "order",
                });
                this.loading = false;
                this.$showResponse("success", response.message);
              }
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });         
       
    },
    getPaymentMethods(){
      this.loading = true
      getPaymentMethods().then(response => {
          this.payment_methods = response.data.data
          this.loading = false
      });
    }
  },
  created() {
    this.getPaymentMethods();     
    this.form.customer_id = this.customer_id; 
    if(this.card){
      this.card_details =  this.card;
      this.form.cardName = this.card.cardName
      this.form.cardNumber = this.card.cardNumber
      this.form.cardMonth = this.card.cardMonth
      this.form.cardYear = this.card.cardYear
      this.form.cardCvv = this.card.cardCvv
    }
    
    if(this.invoice.id) {
      this.form.invoice_id = this.invoice.id
      this.form.due_amount = parseFloat(this.invoice.balance_amount).toFixed(2);
    }
    if(this.Credit){
        this.form.term_limit = parseFloat(this.Credit.available_credit).toFixed(2);
    }

    if(this.invoice.balance_amount > this.Credit.available_credit){
          this.split_staus = true;
    }
  }
}
</script>
<style>
 .use_dif{  
    color: #409EFF;    
    border: 1px solid #DCDFE6;
    text-align: center;
    cursor: pointer;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .card_details{
    
    border: 1px solid #DCDFE6;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    
  }

  .return_product .el-input__inner{
     border: 1.5px solid #ff000091;
    }


</style>

 
