<!-- Product -->
<template>
  <div>
    <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"
        ><i class="flaticon-price-tag"></i> {{ title_text
        }}<span
          v-if="form.name"
          style="text-transform: lowercase; font-size: 15px"
          >({{ form.name }}-<b style="text-transform: uppercase">{{
            form.product_id
          }}</b
          >)</span
        ></span
      >
      <i
        style="float: right; padding: 3px 0"
        class="model-close el-icon-close icon-pointer"
        @click.prevent="closeForm"
      ></i>
    </div>
    <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0">
        <!--begin: Product -->
        <div
          class="wizard wizard-1"
          id="kt_wizard_v1"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Product Body-->
          <div class="row justify-content-center">
            <div class="col-xl-12">
              <!--begin: Wizard Form-->
              <el-form ref="form" :model="form" auto-complete="false">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <div class="row">
                    <div class="col-xl-12">
                      <el-tabs class="root-tab" v-model="activeTab">
                        <el-tab-pane label=" Product Info" name="product_info">
                          <div class="row">
                            <div class="col-xl-2">
                              <el-form-item label="Serial No" prop="serial_id">
                                <el-input
                                  v-model="form.serial_id"
                                  auto-complete="nope"
                                  disabled
                                ></el-input>
                              </el-form-item>
                            </div>
                            <div class="col-xl-3">
                              <el-form-item label="Category" prop="category_id">
                                <el-select
                                  :value="treeLabel"
                                  clearable
                                  placeholder="select category"
                                  ref="selectTree1"
                                  @clear="categoryClear()"
                                  :class="
                                    form.errors.has('category_id')
                                      ? 'error required'
                                      : 'required'
                                  "
                                  :disabled="this.$route.params.product_id"
                                >
                                  <el-option class="add-container" value="">
                                    <a
                                      class="add-drop-btn"
                                      @click="addCategorys"
                                    >
                                      <i class="el-icon-circle-plus"></i>
                                      Add
                                    </a>
                                  </el-option>
                                  <el-option
                                    :value="treeLabel"
                                    :label="treeLabel"
                                    class="tree_option"
                                  >
                                    <el-tree
                                      class="filter-tree"
                                      :data="category"
                                      :props="defaultProps"
                                      node-key="id"
                                      :expand-on-click-node="false"
                                      @current-change="SelectCategory"
                                      :default-expanded-keys="expand"
                                      :highlight-current="true"
                                      @node-click="handleNodeClick"
                                      ref="tree"
                                    >
                                      <span
                                        class="custom-tree-node"
                                        slot-scope="{ data }"
                                      >
                                        <span class="icon-folder">
                                          <i
                                            class="el-icon-folder el-fw"
                                            aria-hidden="true"
                                          ></i>
                                          <span class="icon-folder_text">
                                            {{ data.label }}</span
                                          >
                                        </span>
                                      </span>
                                    </el-tree>
                                  </el-option>
                                </el-select>
                              </el-form-item>
                              <span
                                class="form-err"
                                v-if="form.errors.has('category_id')"
                                >{{ form.errors.get("category_id") }}</span
                              >
                            </div>

                            <div class="col-xl-2">
                              <el-form-item label="SKU" prop="product_id">
                                <el-input
                                  v-model="form.sku"
                                  auto-complete="nope"
                                  disabled
                                ></el-input>
                              </el-form-item>
                            </div>
                            <div class="col-xl-3">
                              <el-form-item label="Product Name" prop="name">
                                <el-input
                                  v-model="form.name"
                                  @input.native="capitalize"
                                  auto-complete="nope"
                                  :class="
                                    form.errors.has('name')
                                      ? 'error required'
                                      : 'required'
                                  "
                                ></el-input>
                                <span
                                  class="form-err"
                                  v-if="form.errors.has('name')"
                                  >{{ form.errors.get("name") }}</span
                                >
                              </el-form-item>
                            </div>
                            <!-- <div class="col-xl-3">
                              <el-form-item label="SKU" prop="sku">
                                <el-input v-model="form.sku" auto-complete="nope" :class="form.errors.has('sku') ? 'error required':'required'"></el-input>
                                <span class="form-err" v-if="form.errors.has('sku')" >{{form.errors.get('sku')}}</span>
                              </el-form-item>
                          </div> -->
                            <div class="col-xl-2">
                              <el-form-item label="Unit Type" prop="unit">
                                <el-select
                                  filterable
                                  v-model="form.unit"
                                  placeholder="Select"
                                  :class="
                                    form.errors.has('unit')
                                      ? 'error required'
                                      : 'required'
                                  "
                                  clearable
                                >
                                  <el-option class="add-container" value="">
                                    <a
                                      class="add-drop-btn"
                                      @click="CreateUnit()"
                                    >
                                      <i class="el-icon-circle-plus"></i>
                                      Add
                                    </a>
                                  </el-option>
                                  <el-option
                                    v-for="(unit, index) in unit_list"
                                    :key="index"
                                    :label="unit.unit_type"
                                    :value="unit.unit_type"
                                    auto-complete="nope"
                                  >
                                  </el-option>
                                </el-select>
                                <span
                                  class="form-err"
                                  v-if="form.errors.has('unit')"
                                  >{{ form.errors.get("unit") }}</span
                                >
                              </el-form-item>
                            </div>
                            <div class="col-xl-3">
                              <el-form-item
                                label="Order Type"
                                prop="order_type"
                              >
                                <el-select
                                  ref="order_type"
                                  v-model="form.order_type"
                                  @change="generateCustomerId($event)"
                                  clearable
                                >
                                  <el-option
                                    v-for="(option, index) in order_typeList"
                                    :key="index"
                                    :label="option.value"
                                    :value="option.id"
                                  >
                                  </el-option>
                                </el-select>
                              </el-form-item>
                            </div>
                            <div class="col-xl-3">
                              <el-form-item
                                label="Product Available"
                                prop="stock_location"
                              >
                                <el-select
                                  ref="stock_location"
                                  v-model="form.stock_location"
                                  clearable
                                  :class="
                                    form.errors.has('stock_location')
                                      ? 'error required'
                                      : 'required'
                                  "
                                  :disabled="this.$route.params.product_id"
                                >
                                  <el-option
                                    v-for="(option, index) in location_list"
                                    :key="index"
                                    :label="option.value"
                                    :value="option.id"
                                  >
                                  </el-option>
                                </el-select>
                                <span
                                  class="form-err"
                                  v-if="form.errors.has('stock_location')"
                                  >{{ form.errors.get("stock_location") }}</span
                                >
                              </el-form-item>
                            </div>

                            <div class="col-xl-2">
                              <el-form-item label="Brand" prop="brand_model">
                                <el-select
                                  filterable
                                  v-model="form.brand_model"
                                  placeholder="Select"
                                  class="dropdown-add"
                                  clearable
                                >
                                  <el-option class="add-container" value="">
                                    <a
                                      class="add-drop-btn"
                                      @click="createBrand()"
                                    >
                                      <i class="el-icon-circle-plus"></i>
                                      Add Brand
                                    </a>
                                  </el-option>
                                  <el-option
                                    v-for="(brand, index) in brand_list"
                                    :key="index"
                                    :label="brand.brand_name"
                                    :value="brand.brand_name"
                                    auto-complete="nope"
                                  >
                                  </el-option>
                                </el-select>
                              </el-form-item>
                            </div>

                            <div class="col-xl-2">
                              <el-form-item label="Weight (kg)" prop="weight">
                                <el-input
                                  v-model="form.weight"
                                  auto-complete="nope"
                                  type="number"
                                ></el-input>
                              </el-form-item>
                            </div>
                            <div class="col-xl-2">
                              <el-form-item label="Length (inch)" prop="length">
                                <el-input
                                  v-model="form.length"
                                  auto-complete="nope"
                                  type="number"
                                ></el-input>
                              </el-form-item>
                            </div>
                            <div class="col-xl-2">
                              <el-form-item label="Width (inch)" prop="width">
                                <el-input
                                  v-model="form.width"
                                  auto-complete="nope"
                                  type="number"
                                ></el-input>
                              </el-form-item>
                            </div>
                            <div class="col-xl-2">
                              <el-form-item label="Height (inch)" prop="height">
                                <el-input
                                  v-model="form.height"
                                  auto-complete="nope"
                                  type="number"
                                ></el-input>
                              </el-form-item>
                            </div>

                            <div class="col-xl-2">
                              <el-form-item label="Status" prop="record_status">
                                <el-select
                                  ref="record_status"
                                  v-model="form.record_status"
                                >
                                  <el-option
                                    v-for="(option, index) in options"
                                    :key="index"
                                    :label="option.text"
                                    :value="option.id"
                                  >
                                  </el-option>
                                </el-select>
                              </el-form-item>
                            </div>
                            <div class="col-xl-3">
                            <el-form-item
                              label="Similar Product"
                              prop="similar_product"
                            >
                              <el-input
                                v-model="form.similar_product"
                                auto-complete="nope"
                                @input.native="capitalizeWord"                            
                              ></el-input>
                            </el-form-item>
                         
                          </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-2">
                              <el-checkbox
                                class="temp-check"
                                true-label="1"
                                false-label="0"
                                v-model="form.temp_products"
                                label="Temporary Product"
                              ></el-checkbox>
                            </div>
                          </div>
                          <div class="d-flex justify-content-end pt-5">
                            <a
                              @click="createProduct"
                              class="text-white"
                              v-if="this.$route.params.product_id"
                            >
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                {{ submit_btn_text }}
                              </div>
                            </a>
                            <a
                              @click="gotoTab('product_price')"
                              class="text-white ml-5"
                              v-if="!this.$route.params.customer_id"
                            >
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                Next
                              </div>
                            </a>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="Product Price" name="product_price">
                          <div class="row">
                            <div class="col-xl-3">
                              <el-form-item
                                label="Square Feet (sq.ft)"
                                prop="sq_feet"
                              >
                                <el-input-number
                                  v-model="form.sq_feet"
                                  auto-complete="nope"
                                  :min="0"
                                  :controls="false"
                                  @change="getPriceCalculation()"
                                  class="min-input"
                                ></el-input-number>
                              </el-form-item>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-12">
                              <h4>
                                Supplier<el-tag
                                  size="mini"
                                  style="margin-left: 10px"
                                  ><a href="#" @click.prevent="createSupplier"
                                    >Add Supplier</a
                                  ></el-tag
                                >
                              </h4>
                            </div>

                            <div
                              class="col-xl-12 row"
                              v-for="(input, k) in form.supplier_list"
                              :key="k"
                            >
                              <div class="col-xl-3">
                                <el-form-item
                                  :label="'Supplier ' + (k + 1)"
                                  prop="sq_feet"
                                >
                                  <el-select
                                    filterable
                                    v-model="input.supplier"
                                    placeholder="Select"
                                    class="dropdown-add"
                                    clearable
                                    @change="setSupplierId(input.supplier, k)"
                                    @clear="SupplierClear(k)"
                                  >
                                    <el-option
                                      v-for="(supplier, index) in supplier_list"
                                      :key="index"
                                      :label="supplier.company_name"
                                      :value="supplier.id"
                                      auto-complete="nope"
                                      :disabled="isExist(supplier.id)"
                                    >
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                                <span
                                  class="form-err"
                                  v-if="!form.supplier_list[k].supplier"
                                  >Please select Supplier</span
                                >
                              </div>
                              <div class="col-xl-2">
                                <el-form-item
                                  :label="
                                    input.supplierID == null
                                      ? 'Supplier ' + (k + 1) + ' ID'
                                      : input.supplierID
                                  "
                                  prop="sq_feet"
                                >
                                  <el-input
                                    v-model="input.supplier_id"
                                    disabled
                                  ></el-input>
                                </el-form-item>
                              </div>
                              <div class="col-xl-2">
                                <el-form-item
                                  label="Supplier SKU"
                                  prop="mfg_id"
                                >
                                  <el-input
                                    v-model="input.mfg_id"
                                    auto-complete="nope"
                                    @change="
                                      supplierSkuValidation(
                                        input.mfg_id,
                                        k,
                                        input.id
                                      )
                                    "
                                  ></el-input>
                                </el-form-item>
                                <span hidden v-loading="email_loading"></span>
                                <span class="form-err">{{
                                  input.supplier_sku_error
                                }}</span>
                              </div>
                              <div class="col-xl-2">
                                <el-form-item
                                  label="MFG Id"
                                  prop="supplier_mfg_id"
                                >
                                  <el-input
                                    v-model="input.supplier_mfg_id"
                                    @change="
                                      supplierMgfValidation(
                                        input.supplier_mfg_id,
                                        k,
                                        input.id
                                      )
                                    "
                                    auto-complete="nope"
                                  ></el-input>
                                </el-form-item>
                                <span hidden v-loading="email_loading"></span>
                                <span class="form-err">{{
                                  input.supplier_mfg_error
                                }}</span>
                              </div>
                              <div class="col-xl-2">
                                <el-form-item label="UPC Code" prop="upc_code">
                                  <el-input
                                    v-model="input.upc_code"
                                    auto-complete="nope"
                                    @change="
                                      checkNo(input.upc_code, k, input.id)
                                    "
                                    onkeypress="return event.keyCode>=48&&event.keyCode<=57"
                                    :maxlength="12"
                                  ></el-input>
                                  <span hidden v-loading="email_loading"></span>
                                  <span class="form-err">{{
                                    input.supplier_upc_error
                                  }}</span>
                                </el-form-item>
                              </div>

                              <div class="col-xl-2">
                                <el-form-item
                                  :label="
                                    input.supplierCost == null
                                      ? 'Cost($)'
                                      : input.supplierCost
                                  "
                                  prop="sq_feet"
                                >
                                  <el-input-number
                                    :precision="2"
                                    v-model="input.supplier_cost"
                                    :disabled="input.supplier == null"
                                    @change="
                                      setSupplierId(input.supplier, k);
                                      handleValidation(k);
                                    "
                                  ></el-input-number>
                                </el-form-item>
                                <span
                                  class="form-err"
                                  v-if="
                                    supplierCostErrorIds.includes(
                                      input.supplier
                                    )
                                  "
                                  >Cost cannot be 0.</span
                                >
                              </div>
                              <div
                                class="col-xl-1"
                                style="margin-top: 35px; margin-left: -20px"
                              >
                                <span
                                  ><i
                                    class="el-icon-circle-close icon-close"
                                    @click="removeSupplier(k)"
                                    v-show="
                                      k || (!k && form.supplier_list.length > 1)
                                    "
                                  ></i
                                ></span>
                                <span
                                  ><i
                                    class="el-icon-circle-plus icon-pointer"
                                    @click="addSupplier(k)"
                                    v-show="k == form.supplier_list.length - 1 && !supplier_id"
                                  ></i
                                ></span>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-3">
                              <el-form-item label="Main Supplier">
                                <el-select
                                  filterable
                                  v-model="form.main_supplier_id"
                                  placeholder="Select"
                                  class="dropdown-add"
                                  clearable
                                  @change="setSupplierDetails"
                                >
                                  <el-option
                                    v-for="(sup, index) in form.supplier_list"
                                    :key="index"
                                    :label="sup.supplier_name"
                                    :value="sup.supplier"
                                    auto-complete="nope"
                                  >
                                  </el-option>
                                </el-select>
                              </el-form-item>
                              <span
                                class="form-err"
                                v-if="form.errors.has('main_supplier_id')"
                                >Please Select Main Supplier</span
                              >
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-xl-3">
                              <el-form-item
                                label="Cost Price($)"
                                prop="cost_price"
                              >
                                <el-input-number
                                  v-model="form.cost_price"
                                  auto-complete="nope"
                                  :min="0"
                                  @change="getPriceCalculation()"
                                  :disabled="!form.main_supplier_id"
                                ></el-input-number>
                                <span
                                  class="form-err"
                                  v-if="form.errors.has('cost_price')"
                                  >{{ form.errors.get("cost_price") }}</span
                                >
                              </el-form-item>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-3">
                              <el-form-item
                                label="Regular (%)"
                                prop="regular_per"
                              >
                                <el-input-number
                                  v-model="form.regular_per"
                                  auto-complete="nope"
                                  @change="getPriceCalculation()"
                                ></el-input-number>
                                <span
                                  class="form-err"
                                  v-if="form.errors.has('regular_per')"
                                  >{{ form.errors.get("regular_per") }}</span
                                >
                              </el-form-item>
                            </div>
                            <div class="col-xl-3">
                              <el-form-item
                                label="Regular Price($)"
                                prop="regular_price"
                              >
                                <el-input
                                  v-model="form.regular_price"
                                  auto-complete="nope"
                                  @change="getPriceCalculation()"
                                  :min="0"
                                  disabled
                                ></el-input>
                              </el-form-item>
                            </div>
                            <div class="col-xl-3">
                              <el-form-item
                                label="Special Price($)"
                                prop="special_price"
                              >
                                <el-input-number
                                  v-model="form.special_price"
                                  auto-complete="nope"
                                  :min="0"
                                  :precision="2"
                                ></el-input-number>
                              </el-form-item>
                            </div>
                            <div class="col-xl-3">
                              <el-form-item
                                label="Inside Price(%)"
                                prop="inside_per"
                              >
                                <el-input-number
                                  v-model="form.inside_per"
                                  auto-complete="nope"
                                  @change="getPriceCalculation()"
                                ></el-input-number>
                              </el-form-item>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-6">
                              <div class="row">
                                <div class="col-xl-12">
                                  <h4>Contractor</h4>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xl-6">
                                  <el-form-item
                                    label="Discount Off(%)"
                                    prop="contractor_discount"
                                  >
                                    <el-input-number
                                      v-model="form.contractor_discount"
                                      auto-complete="nope"
                                      @change="getPriceCalculation()"
                                    ></el-input-number>
                                  </el-form-item>
                                </div>
                                <div class="col-xl-6">
                                  <el-form-item
                                    label="Contract Price($)"
                                    prop="contractor_price"
                                  >
                                    <el-input
                                      v-model="form.contractor_price"
                                      auto-complete="nope"
                                      :min="0"
                                      disabled
                                    ></el-input>
                                  </el-form-item>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-6">
                              <div class="row">
                                <div class="col-xl-12">
                                  <h4>Dealer</h4>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xl-6">
                                  <el-form-item
                                    label="Dealer Margin(%)"
                                    prop="dealer_discount"
                                  >
                                    <el-input-number
                                      v-model="form.dealer_discount"
                                      auto-complete="nope"
                                      @change="getPriceCalculation()"
                                    ></el-input-number>
                                  </el-form-item>
                                </div>
                                <div class="col-xl-6">
                                  <el-form-item
                                    label="Dealer Price($)"
                                    prop="dealer_amount"
                                  >
                                    <el-input
                                      v-model="form.dealer_amount"
                                      auto-complete="nope"
                                      :min="0"
                                      disabled
                                    ></el-input>
                                  </el-form-item>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-xl-12">
                              <div class="row">
                                <div class="col-xl-12">
                                  <h4>Whole Sale</h4>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xl-3">
                                  <el-form-item
                                    label="Discount Off(%)"
                                    prop="contractor_discount"
                                  >
                                    <el-input-number
                                      v-model="form.whole_discount"
                                      auto-complete="nope"
                                      @change="getPriceCalculation()"
                                    ></el-input-number>
                                  </el-form-item>
                                </div>
                                <div class="col-xl-3">
                                  <el-form-item
                                    label="Minimum Amount($)"
                                    prop="whole_min_amount"
                                  >
                                    <el-input-number
                                      v-model="form.whole_min_amount"
                                      auto-complete="nope"
                                      :min="0"
                                      @change="getPriceCalculation()"
                                    ></el-input-number>
                                  </el-form-item>
                                </div>
                                <div class="col-xl-3">
                                  <el-form-item
                                    label="Minimum Quantity"
                                    prop="whole_quantity"
                                  >
                                    <el-input-number
                                      v-model="form.whole_quantity"
                                      auto-complete="nope"
                                      :min="0"
                                      @change="getPriceCalculation()"
                                      disabled
                                    ></el-input-number>
                                  </el-form-item>
                                </div>
                                <div class="col-xl-3">
                                  <el-form-item
                                    label="Whole Sale Price($)"
                                    prop="whole_amount"
                                  >
                                    <el-input
                                      v-model="form.whole_amount"
                                      auto-complete="nope"
                                      :min="0"
                                      disabled
                                    ></el-input>
                                  </el-form-item>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex justify-content-end pt-5">
                            <a
                              @click="gotoTab('product_info')"
                              class="text-white mr-5"
                            >
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                Back
                              </div>
                            </a>
                            <a
                              @click="createProduct"
                              class="text-white mr-5"
                              v-if="this.$route.params.product_id"
                            >
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                {{ submit_btn_text }}
                              </div>
                            </a>
                            <a
                              @click="gotoTab('product_details')"
                              class="text-white"
                            >
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                Next
                              </div>
                            </a>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane
                          label="Product Details"
                          name="product_details"
                        >
                          <div class="row">
                            <div
                              class="col-xl-12"
                              v-for="(input, k) in form.mgf_list"
                              :key="k"
                            >
                              <div class="row">
                                <div class="col-xl-6">
                                  <el-form-item
                                    :label="
                                      input.label == null
                                        ? 'Manufacturer ' + (k + 1)
                                        : input.label
                                    "
                                    prop="mgf_model"
                                  >
                                    <el-input
                                      v-model="input.mgf_model"
                                      auto-complete="nope"
                                    ></el-input>
                                  </el-form-item>
                                </div>
                                <div class="col-xl-4">
                                  <el-form-item
                                    label="Serial Number"
                                    prop="serial_number"
                                  >
                                    <el-input
                                      v-model="input.serial_number"
                                      auto-complete="nope"
                                    ></el-input>
                                  </el-form-item>
                                </div>
                                <div
                                  class="col-xl-2"
                                  style="margin-top: 35px; margin-left: -20px"
                                >
                                  <span
                                    ><i
                                      class="el-icon-circle-close icon-close"
                                      @click="removeMgf(k)"
                                      v-show="
                                        k || (!k && form.mgf_list.length > 1)
                                      "
                                    ></i
                                  ></span>
                                  <span
                                    ><i
                                      class="el-icon-circle-plus icon-pointer"
                                      @click="addMgf(k)"
                                      v-show="k == form.mgf_list.length - 1"
                                    ></i
                                  ></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-6">
                              <el-form-item
                                label="Description"
                                prop="description"
                              >
                                <el-input
                                  type="textarea"
                                  :rows="4"
                                  placeholder="Description"
                                  v-model="form.description"
                                  @input.native="capitalize"
                                >
                                </el-input>
                              </el-form-item>
                            </div>
                            <div class="col-xl-6">
                              <div class="row mt-6">
                                <div class="col-xl-5">
                                  <el-form-item>
                                    <el-checkbox
                                      true-label="1"
                                      false-label="0"
                                      v-model="form.service_req"
                                      >Service Required</el-checkbox
                                    >
                                  </el-form-item>
                                  <el-form-item>
                                    <el-checkbox
                                      true-label="1"
                                      false-label="0"
                                      v-model="form.reg_customer"
                                      >Registered Customer</el-checkbox
                                    >
                                  </el-form-item>
                                  <el-form-item>
                                    <el-checkbox
                                      true-label="1"
                                      false-label="0"
                                      v-model="form.clearance_product"
                                      >Clearance Product</el-checkbox
                                    >
                                  </el-form-item>
                                  <el-form-item>
                                    <el-checkbox
                                      true-label="1"
                                      false-label="0"
                                      v-model="form.catalogue_product"
                                      >Available in Catalogue</el-checkbox
                                    >
                                  </el-form-item>
                                  <el-form-item>
                                  <el-checkbox
                                    true-label="1"
                                    false-label="0"
                                    v-model="form.buy_online_only"
                                    >Buy Online Only</el-checkbox
                                  >
                                </el-form-item>
                                </div>
                                <div class="col-xl-7">
                                  <el-form-item>
                                    <el-checkbox
                                      true-label="1"
                                      false-label="0"
                                      v-model="form.ask_serial"
                                      >Ask Serial Number when Sold</el-checkbox
                                    >
                                  </el-form-item>
                                  <el-form-item>
                                    <el-checkbox
                                      true-label="1"
                                      false-label="0"
                                      v-model="form.refund_exchange"
                                      >Refundable / Exchangeable</el-checkbox
                                    >
                                  </el-form-item>
                                  <el-form-item>
                                    <el-checkbox
                                      true-label="1"
                                      false-label="0"
                                      v-model="form.is_gift"
                                      >Is a Gift Card</el-checkbox
                                    >
                                  </el-form-item>

                                  <el-form-item>
                                    <el-checkbox
                                      true-label="1"
                                      false-label="0"
                                      v-model="form.wholesale_product"
                                      @change="sheckWholesaleAvailable()"
                                      >Available in WholeSale</el-checkbox
                                    >
                                  </el-form-item>
                                  <el-form-item>
                                  <el-checkbox
                                    true-label="1"
                                    false-label="0"
                                    v-model="form.popular_products"
                                    >Popular Products</el-checkbox
                                  >
                                </el-form-item>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-5">
                                <el-form-item
                                  label="Inventory Product"
                                  prop="inventory_prod"
                                >
                                <el-radio v-model="form.inventory_prod" label="1">Yes</el-radio>
                                <el-radio v-model="form.inventory_prod" label="0">No</el-radio>
                                </el-form-item>
                                <span
                                  class="form-err"
                                  v-if="form.errors.has('inventory_prod')"
                                  >{{ form.errors.get("inventory_prod") }}</span
                                >
                            </div>
                          </div>
                          <div class="d-flex justify-content-end pt-5">
                            <a
                              @click="gotoTab('product_price')"
                              class="text-white mr-5"
                            >
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                Back
                              </div>
                            </a>
                            <a
                              @click="createProduct"
                              class="text-white mr-5"
                              v-if="this.$route.params.product_id"
                            >
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                {{ submit_btn_text }}
                              </div>
                            </a>
                            <a
                              @click="gotoTab('product_image')"
                              class="text-white"
                            >
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                Next
                              </div>
                            </a>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="Images" name="product_image">
                          <div class="row">
                            <el-upload
                              action
                              accept="image/jpeg,image/png"
                              list-type="picture-card"
                              :auto-upload="false"
                              :file-list="attachments"
                              :on-remove="removeImage"
                              :before-remove="beforeRemove"
                              :on-change="addAttachment"
                              multiple
                            >
                              <el-button
                                slot="trigger"
                                size="small"
                                type="primary"
                                >Click Upload</el-button
                              >
                            </el-upload>
                            <img
                              width="100%"
                              :src="form.dialogImageUrl"
                              alt=""
                            />
                          </div>
                          <div class="d-flex justify-content-end pt-5">
                            <a
                              @click="gotoTab('product_details')"
                              class="text-white mr-5"
                            >
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                Back
                              </div>
                            </a>
                            <a
                              @click="createProduct"
                              class="text-white mr-5"
                              v-if="this.$route.params.product_id"
                            >
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                {{ submit_btn_text }}
                              </div>
                            </a>
                            <a
                              @click="gotoTab('inventory')"
                              class="text-white"
                              v-if="!this.$route.params.customer_id"
                            >
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                Next
                              </div>
                            </a>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="Inventory" name="inventory">
                          <div class="row">
                            <div class="col-xl-12">
                              <div class="row">
                                <div class="col-xl-12">
                                  <h4>Inventory</h4>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xl-2">
                                  <el-form-item
                                    label="Max Quantity"
                                    prop="max_quantity"
                                  >
                                    <el-input-number
                                      v-model="form.max_quantity"
                                      auto-complete="nope"
                                      :class="
                                        form.errors.has('max_quantity')
                                          ? 'error '
                                          : ''
                                      "
                                      :min="0"
                                      @change="getReorder()"
                                      :controls="false"
                                    ></el-input-number>
                                  </el-form-item>
                                </div>
                                <div class="col-xl-2 col-sm-4 col-md-4">
                                  <el-form-item
                                    label="Min Quantity"
                                    prop="min_quantity"
                                  >
                                    <el-input-number
                                      v-model="form.min_quantity"
                                      auto-complete="nope"
                                      :class="
                                        form.errors.has('min_quantity')
                                          ? 'error '
                                          : ''
                                      "
                                      :min="0"
                                      :max="form.max_quantity"
                                      :disabled="!form.max_quantity"
                                      :controls="false"
                                    ></el-input-number>
                                  </el-form-item>
                                </div>

                                <div class="col-xl-2">
                                  <el-form-item
                                    label="InStock"
                                    prop="stock_quantity"
                                  >
                                    <el-input-number
                                      v-model="form.stock_quantity"
                                      auto-complete="nope"
                                      :class="
                                        form.errors.has('stock_quantity')
                                          ? 'error '
                                          : ''
                                      "
                                      :min="0"
                                      @change="getReorder()"
                                      :controls="false"
                                    ></el-input-number>
                                  </el-form-item>
                                </div>

                                <div class="col-xl-2">
                                  <el-form-item label="Reorder" prop="reorder">
                                    <el-input
                                      v-model="form.reorder"
                                      auto-complete="nope"
                                      :class="
                                        form.errors.has('reorder')
                                          ? 'error '
                                          : ''
                                      "
                                      :min="0"
                                      disabled
                                    ></el-input>
                                  </el-form-item>
                                </div>
                                <div class="col-xl-2">
                                  <el-form-item
                                    label="Over Stock"
                                    prop="over_stock_qty"
                                  >
                                    <el-input
                                      v-model="form.over_stock_qty"
                                      auto-complete="nope"
                                      :class="
                                        form.errors.has('over_stock_qty')
                                          ? 'error '
                                          : ''
                                      "
                                      :min="0"
                                      disabled
                                    ></el-input>
                                  </el-form-item>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xl-3">
                                  <el-form-item
                                    label="Created By"
                                    prop="over_stock_qty"
                                  >
                                    <el-input
                                      v-model="form.created_by"
                                      auto-complete="nope"
                                      :class="
                                        form.errors.has('created_by')
                                          ? 'error required'
                                          : 'required'
                                      "
                                    ></el-input>
                                  </el-form-item>
                                  <span
                                    class="form-err"
                                    v-if="form.errors.has('created_by')"
                                    >{{ form.errors.get("created_by") }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="d-flex justify-content-end pt-5">
                            <a
                              @click="gotoTab('product_image')"
                              class="text-white mr-5"
                            >
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                Back
                              </div>
                            </a>
                            <a @click="createProduct" class="text-white mr-5">
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                {{ submit_btn_text }}
                              </div>
                            </a>
                          </div>
                        </el-tab-pane>
                      </el-tabs>
                    </div>
                  </div>
                </div>
              </el-form>
              <!--end: Product Form-->
            </div>
          </div>
          <!--end: Product Body-->
        </div>
        <v-dialog />
      </div>
      <!--end: Product-->
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/core/services/api.form.services";
import {
  getProduct,
  getAllcategoryList,
  getAllBrandsList,
  getTaxName,
  getCategoryTree,
  deleteCategory,
  deleteImage,
  getProductID,
  createProductId,
  getProductUnitType,
  getSupplierSkuCheck,
  getSupplierUpcCheck,
  getSupplierMfgCheck,
  getProductImage,
  getByIDProductId
} from "@/api/product";
import { getAllSuppliersList, getSupplierDetails } from "@/api/supplier";
//import addeditbrand from "@/view/layout/product/addeditbrand";
import CreateBrand from "@/components/CreateBrand";
import CreateTaxName from "@/components/CreateTax/addTaxname";
import CreateCategory from "@/components/CreateCategory/categoryTree";
import CreateSupplier from "@/components/CreateSupplier/add";
import { globalEvents } from "@/utils/globalEvents";
import { getAllActiveSites } from "@/api/website";
import CreateUnit from "@/components/DropDown/unitType";
import addCategory from "@/components/CreateCategory/addCategory";

export default {
  name: "add-product",
  props: {
    supplier_id: {
      type: Number,
      default() {
        return null;
      },
    },
    category_id: {
      type: Number,
      require: false,
    },
  },
  data() {
    return {
      supplierCostErrorIds: [],
      loading: false,
      email_loading: false,
      activeTab: "product_info",
      attachments: [],
      expand: [],
      tree_select: [],
      form: new Form({
        serial_id: "",
        created_by: "",
        dialogImageUrl: "",
        dialogVisible: false,
        disabled: false,
        attachments: [],
        attachement: [],
        name: null,
        product_id: null,
        sku: null,
        brand_model: null,
        category_id: null,
        category_name: null,
        transport_type: null,
        order_type: "regular_order",
        stock_location: null,
        sq_feet: null,
        unit: null,
        weight: null,
        length: null,
        width: null,
        height: null,
        regular_per: null,
        retail_price: null,
        restocking_fee: null,
        wholesale_price: null,
        contractor_price: null,
        contractor_option: null,
        contractor_discount: null,
        dealer_option: null,
        dealer_discount: null,
        dealer_amount: null,
        whole_discount: null,
        whole_min_amount: null,
        whole_quantity: null,
        whole_amount: null,
        app_tax: null,
        stock_quantity: null,
        over_stock_qty: null,
        reorder: null,
        min_quantity: null,
        max_quantity: null,
        stock_status: null,
        images: null,
        inventory_prod: null,
        service_req: "0",
        reg_customer: "0",
        ask_serial: "0",
        refund_exchange: "1",
        wholesale_product:"0",
        is_gift: "0",
        clearance_product: "0",
        catalogue_product: "0",
        buy_online_only: "0",
        popular_products : "0",
        other_brand: null,
        warr_prod_serial: null,
        description: null,
        record_status: 1,
        main_supplier_id: null,
        cost_price: null,
        special_price: null,
        inside_per: null,
        inside_price: null,
        tax_name: null,
        tax_amount: null,
        default_sale_price: null,
        regular_price: null,
        temp_products: "1",
        upc_list: [
          {
            upc_code: null,
            upc_label: null,
          },
        ],
        supplier_list: [
          {
            supplier: null,
            supplier_id: null,
            supplier_name: null,
            supplierID: null,
            supplierCost: null,
            supplier_cost: null,
            upc_code: null,
            mfg_id: null,
            supplier_mfg_id: null,
            supplier_sku_error: null,
            supplier_upc_error: null,
            supplier_mfg_error: null,
          },
        ],
        mgf_list: [
          {
            mgf_model: null,
            label: null,
            serial_number: null,
          },
        ],
        remove_image: [],
        similar_product:null
      }),
      treeLabel: "",
      checkAll: true,
      isIndeterminate: false,
      websites: [],
      category: [],
      unit_list: [],
      transport_list: [
        {
          value: "Truck",
          id: "truck",
        },
        {
          value: "Parcel",
          id: "parcel",
        },
        {
          value: "Truck/Parcel",
          id: "truck/parcel",
        },
      ],
      location_list: [
        {
          value: "Store",
          id: "Store",
        },
        {
          value: "Store/Web",
          id: "Store/Web",
        },
      ],
      order_typeList: [
        {
          value: "Regular Orders",
          id: "regular_order",
        },
        {
          value: "Pre-Orders",
          id: "pre_orders",
        },
        {
          value: "Customer Orders",
          id: "custome_orders",
        },
      ],

      cat_name: [],
      brand_list: [],
      supplier_list: [],
      category_list: [],
      taxName_list: [],

      images: "../../media/bg/profile-img.png",
      logo_btn_text: null,
      submit_btn_text: "Add Product",
      title_text: "Add Product",
      api_url: "/product/create",
      response_msg: "Product created successfully.",
      options: [
        {
          id: 1,
          text: "Active",
        },
        {
          id: 0,
          text: "In Active",
        },
      ],
      status_list: [
        {
          id: 1,
          text: "In Stock",
        },
        {
          id: 0,
          text: "Out of Stock",
        },
      ],
      selectedOptionId: 1,

      filterText: "",
      defaultProps: {
        children: "children",
        label: "label",
      },

      labelModel: "",
      // actual request value
      valueModel: "0",
      supplier_error: false,
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    labelModel(val) {
      if (!val) {
        this.valueModel = "";
      }
      this.$refs.tree.filter(val);
    },
    value(val) {
      this.labelModel = this.queryTree(this.data, val);
    },
  },
  computed: {},

  mounted() {
    globalEvents.$on("CategoryCreated", (cat) => {
      if (cat) {
        this.category = [];
        this.category = cat[1];
        this.form.category_id = cat[0].id;
        this.form.category_name = cat[0].label;
        this.expand = [cat[0].parent_id];
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(cat[0].id);
        });
      }
    });

    globalEvents.$on("BrandCreated", (brand) => {
      if (brand) {
        this.brand_list.push(brand);
        this.form.brand_id = brand.brand_id;
        this.form.brand_model = brand.brand_name;
      }
    });

    globalEvents.$on("addCategory", (cat) => {
      if (cat) {
        this.getCategory();
        this.form.category_id = cat.id;
        this.expand = [cat.id];
        this.treeLabel = cat.label;
        this.form.category_name = cat.label;
      }
    });

    globalEvents.$on("SupplierCreated", (supplier) => {
      if (supplier) {
        this.form.category_id = supplier.category_id;
        this.supplier_list.push(supplier);
        if (
          this.form.supplier_list[this.form.supplier_list.length - 1]
            .supplier == null
        ) {
          this.form.supplier_list.splice(-1, 1);
          this.form.supplier_list.push({
            supplier: supplier.id,
            supplier_id: supplier.supplier_Id,
            supplier_name: null,
            supplierID: null,
            supplierCost: null,
            supplier_cost: null,
            upc_code: null,
            mfg_id: null,
            supplier_mfg_id: null,
            supplier_sku_error: null,
            supplier_upc_error: null,
            supplier_mfg_error: null,
          });
        } else {
          this.form.supplier_list.push({
            supplier: supplier.id,
            supplier_id: supplier.supplier_Id,
            supplier_name: null,
            supplierID: null,
            supplierCost: null,
            supplier_cost: null,
            upc_code: null,
            mfg_id: null,
            supplier_mfg_id: null,
            supplier_sku_error: null,
            supplier_upc_error: null,
            supplier_mfg_error: null,
          });
        }

        this.form.supplier_id = supplier.supplier_id;
        this.form.supplier_name = supplier.supplier_id;
        this.form.company_name = supplier.company_name;
      }
    });

    globalEvents.$on("TaxName", (taxName) => {
      if (taxName) {
        this.taxName_list.push(taxName);
        this.form.tax_name = taxName.tax_name;
      }
    });

    globalEvents.$on("unitType", (unitType) => {
      if (unitType) {
        this.unit_list.push(unitType);
        this.form.unit = unitType.unit_type;
      }
    });

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
        route: "/dashboard",
      },
      {
        title: "Product List",
        route: "/product/list",
      },
      {
        title: "Add/Edit Product",
      },
    ]);
  },
  methods: {
    handleNodeClick(node) {
      this.treeLabel = node.label;
      this.$refs.selectTree1.blur();
    },
    generateCustomerId(event) {
      const aa = this.form.order_type;
      console.log("aaa", aa, event, "createProductId");
      this.getByIDProductId(event);
    },
    supplierSkuValidation(supplier_sku, index, id) {
      this.supplier_error = false;
      this.form.supplier_list[index].supplier_sku_error = "";
      this.email_loading = true;
      let Product_id = 0;
      if (id) {
        Product_id = id;
      }
      getSupplierSkuCheck(supplier_sku, Product_id)
        .then((response) => {
          this.email_loading = false;
          if (response.status === true) {
            return;
          }
        })
        .catch((error) => {
          //this.supplier_sku_error = error.data.error.mfg_id[0];
          if (error.data.error)
            this.form.supplier_list[index].supplier_sku_error =
              "Supplier SKU has already been taken.";
          this.supplier_error = true;
          this.email_loading = false;
        });
    },
    supplierUpcValidation(supplier_upc, index, id) {
      this.email_loading = true;
      this.supplier_error = false;
      this.form.supplier_list[index].supplier_upc_error = "";
      let Product_id = 0;
      if (id) {
        Product_id = id;
      }
      getSupplierUpcCheck(supplier_upc, Product_id)
        .then((response) => {
          this.supplier_upc_error = "";
          this.email_loading = false;
          if (response.status === true) {
            return;
          }
        })
        .catch((error) => {
          //this.supplier_sku_error = error.data.error.mfg_id[0];
          if (error.data.error)
            this.form.supplier_list[index].supplier_upc_error =
              "UPC Code has already been taken.";
          this.email_loading = false;
          this.supplier_error = true;
        });
    },
    supplierMgfValidation(supplier_mfg, index, id) {
      this.email_loading = true;
      this.supplier_error = false;
      this.form.supplier_list[index].supplier_mfg_error = "";
      let Product_id = 0;
      if (id) {
        Product_id = id;
      }
      getSupplierMfgCheck(supplier_mfg, Product_id)
        .then((response) => {
          this.email_loading = false;
          if (response.status === true) {
            return;
          }
        })
        .catch((error) => {
          //this.supplier_sku_error = error.data.error.mfg_id[0];
          if (error.data.error)
            this.form.supplier_list[index].supplier_mfg_error =
              "MFG Id has already been taken.";
          this.supplier_error = true;
          this.email_loading = false;
        });
    },
    checkNo(value, index, id) {
      let reg = /^[1-9]\d*$/;
      if (value) {
        if (value > 999999 || new RegExp(reg).test(value) == false) {
          setTimeout(() => {
            this.num = "";
          }, 500);
        }
        this.supplierUpcValidation(value, index, id);
      }
    },
    beforeRemove(file) {
      return this.$confirm(`Do you really want to delete ${file.name}？`);
    },
    handleRemove(file) {
      console.log(file);
      // for(var i = 0; i < this.uploadData.length; i++){
      //            if(file.uid == this.uploadData[i].uid){
      //                this.uploadData.splice(i,1)
      //            }
      //        }
    },
    categoryClear() {
      this.treeLabel = "";
      this.form.category_id = "";
      this.form.category_name = "";
      this.expand = [];
      this.getProductID;
    },
    SelectCategory(currentObj) {
      this.form.category_id = currentObj.id;
      this.form.category_name = currentObj.label;
    },

    add() {
      this.form.upc_list.push({ upc_code: "", upc_label: null });
    },
    remove(index) {
      this.form.upc_list.splice(index, 1);
    },
    addMgf() {
      this.form.mgf_list.push({
        mgf_model: "",
        label: null,
        serial_number: null,
      });
    },
    removeMgf(index) {
      this.form.mgf_list.splice(index, 1);
    },

    handleSupplierCostCheck() {
      return this.form.supplier_list.every((item) => item.supplier_cost > 0);
    },

    gotoTab(tabname) {
      this.loading = true;
      if (tabname == "product_price") {
        if (
          !this.form.name ||
          !this.form.stock_location ||
          !this.form.category_id ||
          !this.form.unit
        ) {
          this.form.errors.clear();
          if (!this.form.product_id) {
            this.form.errors.add(
              "product_id",
              "The Product ID field is required."
            );
          }
          if (!this.form.name) {
            this.form.errors.add("name", "The Product name field is required.");
          }
          if (!this.form.name) {
            this.form.errors.add("name", "The Product name field is required.");
          }

          if (!this.form.category_id) {
            this.form.errors.add("category_id", "Category field is required.");
          }

          if (!this.form.stock_location) {
            this.form.errors.add(
              "stock_location",
              "The Stock Location field is required."
            );
          }
          if (!this.form.unit) {
            this.form.errors.add("unit", "Unit Type is required.");
          }
        } else {
          this.activeTab = tabname;
        }
      } else if (tabname == "product_details") {
        this.form.errors.clear();
        if (
          !this.form.cost_price ||
          !this.form.regular_per ||
          !this.form.main_supplier_id ||
          !this.handleSupplierCostCheck()
        ) {
          if (
            this.form.supplier_list.length >= 0 ||
            !this.handleSupplierCostCheck()
          ) {
            this.supplierCostErrorIds = this.form.supplier_list
              .map((item) =>
                item.supplier_cost <= 0 || item.supplier_cost === undefined
                  ? item.supplier
                  : null
              )
              .filter((item2) => item2 !== null);
          }

          if (!this.form.regular_per) {
            this.form.errors.add(
              "main_supplier_id",
              "The Main Supplier is required."
            );
          }
          if (!this.form.cost_price) {
            this.form.errors.add(
              "cost_price",
              "The Cost Price field is required."
            );
          }
          if (!this.form.regular_per) {
            this.form.errors.add(
              "regular_per",
              "The Regular Price % is required."
            );
          }
        } else {
          this.activeTab = tabname;
        }
      }else if(tabname == "product_image"){
        this.form.errors.clear();
        this.isFormInValid = false;
        if (!this.form.inventory_prod) {
            this.form.errors.add(
              "inventory_prod",
              "Inventory Product status required"
            );
          }else{
            this.activeTab = tabname;
           this.isFormInValid = false;
          }
      } else {
        this.form.errors.clear();
        this.activeTab = tabname;
      }
      this.loading = false;
    },
    handleTabClick(tab) {
      this.activeTab = tab;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    setSamePriceToAllWebsite() {
      this.form.website_price.map((website) => {
        if (website.is_same_price) {
          website.sale_price = this.form.default_sale_price;
          website.retail_price = this.form.default_regular_price;
        }
      });
    },
    setSupplierDetails() {
      this.supplier_list.map((supplier) => {
        if (this.form.main_supplier_id == supplier.id) {
          this.form.regular_per = supplier.regular_percentage;
          this.form.contractor_discount = supplier.contractor_percentage;
          this.form.dealer_discount = supplier.dealer_percentage;
          this.form.whole_discount = supplier.wholesale_percentage;
          this.form.supplier_list.map((prd_supplier) => {
            if (prd_supplier.supplier == supplier.id) {
              this.form.cost_price = prd_supplier.supplier_cost;
            }
          });
        }
      });
      if (this.form.cost_price) {
        this.getPriceCalculation();
      }
    },
    addSameprice(value, index) {
      alert(value, index);
    },
    isExist(supplier_id) {
      for (var i = 0; i < this.form.supplier_list.length; i++) {
        if (this.form.supplier_list[i].supplier == supplier_id) {
          return true;
        }
      }
      return false;
    },
    addSupplier() {
      this.form.supplier_list.push({
        supplier: null,
        supplier_id: null,
        supplier_name: null,
        supplierID: null,
        supplierCost: null,
        supplier_cost: "0.00",
        upc_code: null,
        mfg_id: null,
        // mfg_id : null,  Selvan Duplicate key
        supplier_mfg_id: null,
        supplier_sku_error: null,
        supplier_upc_error: null,
      });
    },
    removeSupplier(index) {
      this.form.supplier_list.splice(index, 1);
      if (this.form.supplier_list.length == 1) {
        this.form.main_supplier_id = this.form.supplier_list[0].supplier;
        this.form.cost_price = this.form.supplier_list[0].supplier_cost;
        this.setSupplierDetails();
      }
    },
    SupplierClear(index) {
      this.form.supplier_list[index].supplier_id = "";
      this.form.supplier_list[index].supplier_cost = "";
      this.form.supplier_list[index].upc_code = "";
      this.form.supplier_list[index].supplier_name = "";
      this.form.supplier_list[index].supplier = "";
      this.form.supplier_list[index].mfg_id = "";
      this.form.main_supplier_id = "";
    },
    getPriceCalculation() {
      const regular_price =
        this.form.cost_price +
        this.form.cost_price * (this.form.regular_per / 100);
      const dealer =
        this.form.cost_price +
        this.form.cost_price * (this.form.dealer_discount / 100);
      const contract =
        regular_price -
        this.form.regular_price * (this.form.contractor_discount / 100);
      const whole =
        regular_price -
        this.form.regular_price * (this.form.whole_discount / 100);
      const inside = contract + contract * (this.form.inside_per / 100);
      this.form.regular_price = "";
      if (this.form.regular_per && this.form.cost_price) {
        this.form.regular_price = regular_price.toFixed(2);
      }

      this.form.dealer_amount = "";
      if (this.form.dealer_discount && this.form.cost_price) {
        this.form.dealer_amount = dealer.toFixed(2);
      }

      this.form.contractor_price = "";
      if (
        this.form.contractor_discount &&
        this.form.regular_per &&
        this.form.cost_price
      ) {
        this.form.contractor_price = contract.toFixed(2);
      }
      this.form.whole_amount = "";
      if (
        this.form.whole_discount &&
        this.form.regular_per &&
        this.form.cost_price
      ) {
        this.form.whole_amount = whole.toFixed(2);
      }else{
          this.form.wholesale_product = "0"
      }

      this.form.inside_price = "";

      if (
        this.form.contractor_discount &&
        this.form.regular_per &&
        this.form.cost_price
      ) {
        this.form.inside_price = inside.toFixed(2);
      }
      this.form.whole_quantity = Math.round(
        this.form.whole_min_amount / this.form.regular_price
      );
    },
    getReorder() {
      this.form.reorder = "";
      this.form.over_stock_qty = null;
      if (this.form.max_quantity) {
        this.form.reorder =
          this.form.max_quantity - this.form.stock_quantity > 0
            ? this.form.max_quantity - this.form.stock_quantity
            : 0;
        // this.form.stock_quantity = this.form.stock_quantity > this.form.max_quantity ? this.form.max_quantity : this.form.stock_quantity
      } else {
        this.form.min_quantity = 0;
        this.form.stock_quantity = 0;
        this.form.reorder = 0;
      }
      if (this.form.stock_quantity > this.form.max_quantity) {
        this.form.over_stock_qty =
          this.form.stock_quantity - this.form.max_quantity;
      }
    },
    getCategory() {
      this.loading = true;
      getCategoryTree().then((response) => {
        this.category = response.data.data;
        this.loading = false;
      });
    },
    createProductId() {
      this.loading = true;

      //this.loading = true
      createProductId().then((response) => {
        this.form.sku = response.data;
        this.loading = false;
      });
      //this.getAllSuppliersList(categoryId);
      //this.loading = false;
    },
    getByIDProductId(order_type) {
      this.loading = true;
      getByIDProductId(order_type).then((response) => {
        this.form.sku = response.data;
        console.log("getting");
        this.loading = false;
      });
    },
    clearCategory() {
      this.$refs.tree.setCurrentKey();
      this.form.category_name = "";
      this.form.category_id = "";
    },
    getProductID() {
      this.loading = true;
      getProductID().then((response) => {
        this.form.serial_id = response.data.id ? response.data.id + 1 : 1;
      });
    },
    getProductUnitType() {
      this.loading = true;
      getProductUnitType().then((response) => {
        this.unit_list = response.data.data;
      });
    },
    getAllActiveSites() {
      this.loading = true;
      getAllActiveSites().then((response) => {
        this.websites = response.data.data;
        this.websites.map((website) => {
          this.form.checkedSites.push(website.website_id);
          this.handleCheckAllChange(true);
        });
        this.loading = false;
      });
    },
    checkAllWebsitesByIds() {
      this.websites.map((website) => {
        this.form.checkedSites.push(website.website_id);
      });
    },
    handleChecked(web_id, event) {
      if (event == true) {
        this.websites.map((website) => {
          if (website.website_id == web_id) {
            this.form.website_price.push({
              store_id: website.website_id,
              store_name: website.website_name,
              sale_price: null,
              retail_price: null,
              is_same_price: true,
            });
          }
        });
      } else {
        var removeIndex = this.form.website_price
          .map((item) => item.store_id)
          .indexOf(web_id);
        this.form.website_price.splice(removeIndex, 1);
      }
      this.isIndeterminate = false;
    },
    handleCheckAllChange(val) {
      this.form.website_price = [];
      this.form.checkedSites = [];
      if (val) {
        this.websites.map((website) => {
          this.form.checkedSites.push(website.website_id);
          this.form.website_price.push({
            store_id: website.website_id,
            store_name: website.website_name,
            sale_price: null,
            retail_price: null,
            is_same_price: true,
          });
        });
      }
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.websites.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.websites.length;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if (val.length > 0) {
        let retail_price = val[0].retail_price;
        let sale_price = val[0].sale_price;
        val.forEach((element) => {
          (element.retail_price = retail_price),
            (element.sale_price = sale_price);
        });
      }
    },
    createProduct: function () {
      if (this.supplier_error == false) {
        this.loading = true;
        this.form
          .post(this.api_url)
          .then((response) => {
            if (response.status) {
              this.loading = false;
              globalEvents.$emit("productAdd", response.data);
              this.$emit("close");
              this.$showResponse("success", response.message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length <= 0) {
        return;
      }
      this.form.images = files[0];
      this.images = URL.createObjectURL(files[0]);
    },
    viewAllProducts() {
      this.$router.push({
        path: "/product/list",
      });
    },
    getAllcategoryList() {
      this.loading = true;
      getAllcategoryList().then((response) => {
        this.category_list = response.data.data;
        this.loading = false;
      });
    },
    getAllBrandsList() {
      this.loading = true;
      getAllBrandsList().then((response) => {
        this.brand_list = response.data.data;
        this.loading = false;
      });
    },
    sheckWholesaleAvailable(){     
     if( this.form.wholesale_product == 1){      
       if(!this.form.whole_amount){
         const h = this.$createElement;
             this.$notify({
               message: h(
                 "i",
                 {
                   style: "color: red",
                 },
                 "Please Enter the whole sale amount"
               ),
               offset: 200,
               type: "warning",
             });
             this.form.wholesale_product = "0"
         }
       }
   },
    getAllSuppliersList() {
      this.loading = true;
      getAllSuppliersList(this.form.category_id).then((response) => {
        this.supplier_list = response.data.data;
        if (!this.$route.params.product_id) {
          if (!this.supplier_id) {
            //this.addSupplier();
          }
        }
        if (this.$route.params.supplierId && this.supplier_list.length == 1) {
          this.form.supplier_list[0].supplier = this.supplier_list[0].id;
          this.form.supplier_list[0].supplier_name =
            this.supplier_list[0].company_name;
          this.form.main_supplier_id = this.supplier_list[0].id;
          this.setSupplierId(this.$route.params.supplierId, 0);
        }

        if (this.supplier_id) {
          this.supplier_list.map((supplier) => {
            if (supplier.id == this.supplier_id) {
              this.form.supplier_list[0].supplier = supplier.id;
              this.form.supplier_list[0].supplier_name = supplier.company_name;
              this.form.main_supplier_id = supplier.id;
              this.setSupplierId(this.$route.params.supplierId, 0);
            }
          });
        }

        this.loading = false;
      });
    },
    getTaxNameList() {
      this.loading = true;
      getTaxName().then((response) => {
        this.taxName_list = response.data.data;
        this.loading = false;
      });
    },

    addAttachment(file) {
      let fileDocument = [];
      fileDocument.push(file);
      this.form.attachment = [];
      fileDocument.map(async (attachment) => {
        let fileName = attachment.name;
        let blob = this.blobIsExists(fileName);
        if (blob) {
          this.form.attachments.push({
            image_path: fileName,
            image_blob: blob,
          });
        } else if (file != null) {
          blob = await this.readFile(attachment.raw);

          this.form.attachments.push({
            image_path: fileName,
            image_blob: blob,
          });
        }
      });
    },

    removeImage(file, fileList) {
      deleteImage(file.name).then((response) => {
        if (response) {
          return true;
        }
        this.loading = false;
      });

      this.form.attachments = [];
      fileList.map(async (attachment) => {
        let fileName = attachment.name;
        let blob = this.blobIsExists(fileName);
        if (blob) {
          this.form.attachments.push({
            image_path: fileName,
            image_blob: blob,
          });
        } else if (file != null) {
          if (attachment.status === "success") {
            this.form.attachments.push({
              image_path: attachment.name,
              image_blob: attachment.url,
            });
          } else {
            blob = await this.readFile(attachment.raw);
            this.form.attachments.push({
              image_path: fileName,
              image_blob: blob,
            });
          }
        }
      });
    },
    readFile(file) {
      return new Promise((resolve) => {
        const fReader = new FileReader();
        fReader.onload = () => {
          resolve(fReader.result);
        };
        fReader.readAsDataURL(file);
      });
    },
    blobIsExists(filename) {
      let blob = null;
      this.attachments.map((attachment) => {
        if (attachment.name === filename) {
          blob = attachment.blob;
        }
      });
      return blob;
    },
    createBrand() {
      this.$modal.show(
        CreateBrand,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "600px",
        }
      );
    },

    createSupplier() {
      this.$modal.show(
        CreateSupplier,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "800",
        }
      );
    },
    CreateTaxName() {
      this.$modal.show(
        CreateTaxName,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    addBrand() {
      this.form.brand_model.push({
        id: "",
      });
    },
    addCategory() {
      this.form.cat_name.push({
        id: "",
      });
    },

    handleValidation(index) {
      if (
        this.form.supplier_list[index].hasOwnProperty("supplier_cost") &&
        this.form.supplier_list[index].supplier_cost > 0
      ) {
        this.supplierCostErrorIds = this.supplierCostErrorIds.filter(
          (item) => item !== this.form.supplier_list[index].supplier
        );
      } else {
        this.supplierCostErrorIds.push(this.form.supplier_list[index].supplier);
      }
    },

    setSupplierId(supplier_id, index) {
      this.supplier_list.map((supplier) => {
        if (supplier.id == supplier_id) {
          this.form.supplier_list[index].supplier_id = supplier.supplier_Id;
          this.form.supplier_list[index].supplier_name = supplier.company_name;
        }
      });
      if (this.form.supplier_list.length == 1) {
        this.form.main_supplier_id = this.form.supplier_list[0].supplier;
        this.form.cost_price = this.form.supplier_list[0].supplier_cost;
        this.setSupplierDetails();
      }
      if (this.form.main_supplier_id == supplier_id) {
        this.form.cost_price = this.form.supplier_list[index].supplier_cost;
        this.setSupplierDetails();
      }
    },

    CreateCategory() {
      let Parentdata = this.$refs.tree.getCurrentNode();
      let data = {};
      if (Parentdata) {
        data = Parentdata;
      }
      this.$modal.show(
        CreateCategory,
        {
          parentData: data,
          category_action: "add",
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "600px",
        }
      );
    },

    addCategorys() {
      let category_name = "";
      if (this.form.category_id) {
        this.category_list.map((cateName) => {
          if (cateName.id == this.form.category_id) {
            category_name = cateName.label;
          }
        });
      }

      let parentId = this.form.category_id != null ? this.form.category_id : 0;
      this.$modal.show(
        addCategory,
        {
          parentId: parentId,
          category_action: "add",
          category_name: category_name,
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "600px",
        }
      );
    },

    EditCategory() {
      let Parentdata = this.$refs.tree.getCurrentNode();
      this.$modal.show(
        CreateCategory,
        {
          parentData: Parentdata,
          category_action: "edit",
        },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "600px",
        }
      );
    },
    deletecatecory() {
      let Parentdata = this.$refs.tree.getCurrentNode();
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to delete ?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            // this.loading = true;
            deleteCategory(Parentdata.id).then((response) => {
              this.category = [];
              if (response.data.status) {
                // this.$showResponse('success', response.data.message);
                this.category = response.data.data;
                this.expand = [response.data.parent_id];
                this.$nextTick(() => {
                  this.$refs.tree.setCurrentKey(response.data.parent_id);
                });
                this.form.category_id = "";
                this.form.category_name = "";
              }
              //this.loading = false;
            });
          } else {
            return false;
          }
        });
    },

    CreateUnit() {
      this.$modal.show(
        CreateUnit,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    closeForm() {
      if (!this.form.submiting) {
        globalEvents.$emit("SupplierCreated", false);
        this.$emit("close");
      }
    },
    setDefaultSupplier(supplier_id) {
      this.loading = true;
      getSupplierDetails(supplier_id).then((response) => {
        this.selected_supplier = response.data.data;
        this.form.category_id = response.data.data.category_id;
        this.form.regular_per = response.data.data.regular_percentage;
        this.form.contractor_discount =
          response.data.data.contractor_percentage;
        this.form.dealer_discount = response.data.data.dealer_percentage;
        this.form.whole_discount = response.data.data.wholesale_percentage;
        this.expand = [response.data.data.id];
        this.treeLabel = response.data.data.category.label;       
        this.loading = false;
      });
    },
  },

  created() {
    this.createProductId();
    //this.getAllActiveSites();
    this.getAllcategoryList();
    this.getAllBrandsList();
    this.getTaxNameList();
    this.getCategory();
    this.getProductID();

    this.getAllSuppliersList();

    this.getProductUnitType();
    if (this.supplier_id) {
      this.setDefaultSupplier(this.supplier_id);
    }
    let product_id = this.$route.params.product_id;
    this.logo_btn_text = "Add Logo";
    this.crstatus = "1";
    if (parseInt(product_id) > 0) {
      this.logo_btn_text = "Update Logo";
      this.submit_btn_text = "Update";
      this.title_text = "Update Product";
      this.action = "update";
      this.response_msg = "Product updated successfully.";
      this.upstatus = "1";

      getProduct(this.$route.params.product_id).then((response) => {
        if (response.data.data.id) {
          this.api_url = "product/edit/" + response.data.data.id;
        }
        this.form = new Form(response.data.data);
        this.form.serial_id = response.data.data.id;
        this.form.category_id = response.data.data.product_category.id;
        this.form.category_name = response.data.data.product_category.label;
        this.treeLabel = response.data.data.product_category.label;
        this.expand = [response.data.data.product_category.id];

        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(response.data.data.product_category.id);
        });
        if (response.data.data.upc_list == "") {
          this.form.upc_list = [
            {
              upc_code: null,
              upc_label: null,
            },
          ];
        }
        if (response.data.data.mgf_list == "") {
          this.form.mgf_list = [
            {
              mgf_model: null,
              label: null,
              serial_number: null,
            },
          ];
        }
        if (response.data.data.supplier_list == "") {
          this.form.supplier_list = [
            {
              supplier: null,
              supplier_id: null,
              supplier_name: null,
              supplierID: null,
              supplierCost: null,
              supplier_cost: null,
              upc_code: null,
              mfg_id: null,
              supplier_mfg_id: null,
              supplier_sku_error: null,
              supplier_upc_error: null,
              supplier_mfg_error: null,
            },
          ];
        }
        if (this.form.website_price) {
          this.form.website_price.map((website) => {
            if (website.is_same_price) {
              website.is_same_price = true;
            } else {
              website.is_same_price = false;
            }
          });
        }
        getProductImage(this.form.sku).then((response) => {
          const Images = response.data.data;
          for (var i = 0; i < Images.length; i++) {
            this.attachments.push({
              name: Images[i].name,
              url: Images[i].url,
              id: Images[i].id,
            });
          }
        });
      });
    }
  },
};
</script>
<style scoped>
.add_upc {
  position: absolute;
}
.el-scrollbar__view .el-select-dropdown__list {
  padding-bottom: 0px !important;
}
.tree_option {
  overflow-y: scroll;
  height: auto;
  padding: 10px 10px;
}

.add_option {
  padding: 0px;
  overflow-y: unset;
}
.temp-check {
  margin-top: 14px;
}

</style>


