<!-- Settings -->
<template>
  <div>
    <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"
        ><i class="flaticon2-user" aria-hidden="true"></i
        >{{ label.title }}</span
      >
      <i
        style="float: right; padding: 3px 0"
        class="model-close el-icon-close icon-pointer"
        @click.prevent="closeForm"
      ></i>
    </div>
    <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0">
        <!--begin: Settings-->
        <div
          class="wizard wizard-1"
          id="kt_wizard_v1"
          data-wizard-state="step-first"
          data-wizard-clickable="true"
        >
          <!--begin: Wizard Body-->
          <div class="row justify-content-center">
            <div class="col-xl-12">
              <!--begin: Wizard Form-->
              <el-form ref="form" :model="form" auto-complete="nope">
                <!--begin: Wizard Step 1-->
                <div
                  class="pb-5"
                  data-wizard-type="step-content"
                  data-wizard-state="current"
                >
                  <div class="row">
                    <div class="col-xl-12">
                      <el-tabs class="root-tab" v-model="activeTab">
                        <el-tab-pane
                          label="Customer Details"
                          name="customer_details"
                        >
                          <div class="row">
                            <div class="col-xl-9">
                              <div class="row">
                                <div class="col-xl-4">
                                  <el-form-item
                                    label="Customer Id asd"
                                    prop="customer_id"
                                  >
                                    <el-input
                                      v-model="form.customer_id"
                                      auto-complete="nope"
                                      disabled
                                      :class="
                                        form.errors.has('customer_id')
                                          ? 'error required'
                                          : ''
                                      "
                                    ></el-input>
                                    <span
                                      class="form-err"
                                      v-if="form.errors.has('customer_id')"
                                      >{{
                                        form.errors.get("customer_id")
                                      }}</span
                                    >
                                  </el-form-item>
                                </div>
                                <div class="col-xl-4">
                                  <el-form-item
                                    label="First Name"
                                    prop="first_name"
                                  >
                                    <el-input
                                      v-model="form.first_name"
                                      auto-complete="nope"
                                      @input.native="capitalize"
                                      :class="
                                        form.errors.has('first_name')
                                          ? 'error required'
                                          : 'required'
                                      "
                                    ></el-input>
                                    <span
                                      class="form-err"
                                      v-if="form.errors.has('first_name')"
                                      >{{ form.errors.get("first_name") }}</span
                                    >
                                  </el-form-item>
                                </div>
                                <div class="col-xl-4">
                                  <el-form-item
                                    label="Last Name"
                                    prop="last_name"
                                  >
                                    <el-input
                                      v-model="form.last_name"
                                      auto-complete="nope"
                                      @input.native="capitalize"
                                      :class="
                                        form.errors.has('last_name')
                                          ? 'error '
                                          : ''
                                      "
                                    ></el-input>
                                  </el-form-item>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-xl-4">
                                  <el-form-item
                                    label="Company Name"
                                    prop="company_name"
                                  >
                                    <el-input
                                      @input.native="capitalize"
                                      v-model="form.company_name"
                                      auto-complete="nope"
                                      :class="
                                        form.errors.has('company_name')
                                          ? 'error '
                                          : ''
                                      "
                                    ></el-input>
                                  </el-form-item>
                                </div>
                                <div class="col-xl-4">
                                  <el-form-item
                                    label="Email"
                                    prop="email_address"
                                  >
                                    <el-input
                                      v-model="form.email_address"
                                      auto-complete="nope"
                                      @change="
                                        getEmailCheck(form.email_address)
                                      "
                                        :class="
                                      form.errors.has('email_address')
                                        ? 'error required'
                                        : 'required'
                                    "
                                    ></el-input>
                                  </el-form-item>
                                  <span
                                    class="form-err"
                                    v-if="form.errors.has('email_address') && !email_error"
                                    >{{ form.errors.get("email_address") }}</span
                                  >
                                  <span class="form-err" v-if="email_error">{{
                                    email_error
                                  }}</span>
                                </div>
                                <div class="col-xl-4">
                                  <el-form-item
                                    label="Phone I"
                                    prop="customer_mobile"
                                  >
                                    <el-input
                                      v-model="form.customer_mobile"
                                      auto-complete="nope"
                                      :class="
                                        form.errors.has('customer_mobile')
                                          ? 'phone_check_error phone_check'
                                          : 'phone_check'
                                      "
                                      @keyup.native="mobileNumberValidation"
                                    >
                                      <template slot="prepend">+1</template>
                                    </el-input>
                                    <span
                                      class="form-err"
                                      v-if="!phone_error"
                                      >{{
                                        form.errors.get("customer_mobile")
                                      }}</span
                                    >
                                    <span
                                      hidden
                                      v-loading="email_loading"
                                    ></span>
                                    <span class="form-err" else>{{
                                      phone_error
                                    }}</span>
                                  </el-form-item>
                                </div>
                                <div class="col-xl-4">
                                  <el-form-item
                                    label="Phone II"
                                    prop="customer_phone"
                                  >
                                    <el-input
                                      v-model="form.customer_phone"
                                      auto-complete="nope"
                                      @keyup.native="phoneNumberValidation"
                                      class="phone_check1"
                                    >
                                      <template slot="prepend">+1</template>
                                    </el-input>
                                    <span
                                      class="form-err"
                                      v-if="!phone1_error"
                                      >{{
                                        form.errors.get("customer_phone")
                                      }}</span
                                    >
                                    <span
                                      hidden
                                      v-loading="email_loading"
                                    ></span>
                                    <span class="form-err" else>{{
                                      phone1_error
                                    }}</span>
                                  </el-form-item>
                                </div>
                                <div class="col-xl-4">
                                  <el-form-item label="Fax" prop="customer_fax">
                                    <el-input
                                      v-model="form.customer_fax"
                                      auto-complete="nope"
                                      @input.native="capitalize"
                                      :class="
                                        form.errors.has('customer_fax')
                                          ? 'error '
                                          : ''
                                      "
                                    ></el-input>
                                  </el-form-item>
                                </div>
                                <div class="col-sm-4">
                                  <el-form-item label="Store" prop="store">
                                    <el-select
                                      v-model="form.website_id"
                                      :class="
                                        form.errors.has('website_name')
                                          ? 'error required'
                                          : 'required'
                                      "
                                      @change="generateCustomerId()"
                                    >
                                      <el-option
                                        v-for="(
                                          website, index
                                        ) in active_website"
                                        :key="index"
                                        :label="website.website_name"
                                        :value="website.website_id"
                                      >
                                      </el-option>
                                    </el-select>
                                  </el-form-item>
                                  <span
                                    class="form-err"
                                    v-if="form.errors.has('website_id')"
                                    >The store field is required.</span
                                  >
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-xl-4">
                                  <el-form-item label="Status" prop="active">
                                    <el-select v-model="form.active">
                                      <el-option
                                        v-for="(option, index) in options"
                                        :key="index"
                                        :label="option.text"
                                        :value="option.text"
                                      >
                                      </el-option>
                                    </el-select>
                                  </el-form-item>
                                </div>
                                <div class="col-sm-4">
                                  <el-form-item label="Address" prop="street">
                                    <vue-google-autocomplete
                                      ref="origin"
                                      v-model="form.street"
                                      id="map"
                                      classname="form-control el-input__inner"
                                      name="street"
                                      placeholder="Enter your Address"
                                      v-on:placechanged="getAddressData"
                                      country="ca"
                                    >
                                    </vue-google-autocomplete>

                                    <span
                                      class="form-err"
                                      v-if="form.errors.has('street')"
                                      >Address field is required.</span
                                    >
                                  </el-form-item>
                                </div>
                                <div class="col-sm-4">
                                  <el-form-item
                                    label="Unit Number"
                                    prop="unit_no"
                                  >
                                    <el-input
                                      v-model="form.unit_no"
                                      auto-complete="nope"
                                      @input.native="capitalize"
                                    ></el-input>
                                    <span
                                      class="form-err"
                                      v-if="form.errors.has('unit_no')"
                                      >The Unit Number field is required.</span
                                    >
                                  </el-form-item>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-sm-4">
                                  <el-form-item label="Province" prop="state">
                                    <el-select
                                      ref="state"
                                      filterable
                                      clearable
                                      v-model="form.state_id"
                                      @change="
                                        getCityListByState(form.state_id)
                                      "
                                      :class="
                                        form.errors.has('state_id')
                                          ? 'error required'
                                          : 'required'
                                      "
                                    >
                                      <el-option
                                        v-for="(state, index) in state_list"
                                        :key="index"
                                        :label="state.state_name"
                                        :value="state.state_id"
                                      >
                                      </el-option>
                                    </el-select>
                                    <span
                                      class="form-err"
                                      v-if="form.errors.has('state_id')"
                                      >The Province field is required.</span
                                    >
                                  </el-form-item>
                                </div>
                                <div class="col-sm-4">
                                  <el-form-item label="City" prop="city">
                                    <el-select
                                      ref="city"
                                      filterable
                                      clearable
                                      v-model="form.city_id"
                                      :class="
                                        form.errors.has('city_id')
                                          ? 'error required'
                                          : 'required'
                                      "
                                    >
                                      <el-option class="add-container" value="">
                                        <a
                                          class="add-drop-btn"
                                          @click="CreateCity()"
                                        >
                                          <i class="el-icon-circle-plus"></i>
                                          Add City
                                        </a>
                                      </el-option>
                                      <el-option
                                        v-for="(city, index) in city_list"
                                        :key="index"
                                        :label="city.city_name"
                                        :value="city.city_id"
                                      >
                                      </el-option>
                                    </el-select>
                                    <span
                                      class="form-err"
                                      v-if="form.errors.has('city_id')"
                                      >The City field is required.</span
                                    >
                                  </el-form-item>
                                </div>
                                <div class="col-sm-4">
                                  <el-form-item
                                    label="Postal Code"
                                    prop="post_code"
                                  >
                                    <el-input
                                      v-model="form.post_code"
                                      auto-complete="nope"
                                      :class="
                                        form.errors.has('post_code')
                                          ? 'error required'
                                          : 'required'
                                      "
                                      placeholder="M1S 3A7"
                                      @change="validPostal(form.post_code)"
                                      :maxlength="7"
                                    ></el-input>
                                    <span
                                      class="form-err"
                                      v-if="form.errors.has('post_code')"
                                      >The Postal Code field is required.</span
                                    >
                                  </el-form-item>
                                </div>
                                <div class="col-xl-4">
                                  <el-form-item
                                    label="Type"
                                    prop="customer_type"
                                  >
                                    <el-select
                                      filterable
                                      v-model="form.type_id"
                                      placeholder="Select"
                                      class="dropdown-add"
                                    >
                                      <el-option class="add-container" value="">
                                        <a
                                          class="add-drop-btn"
                                          @click="CreateType()"
                                        >
                                          <i class="el-icon-circle-plus"></i>
                                          Add Type
                                        </a>
                                      </el-option>
                                      <el-option
                                        v-for="(ct_type, index) in type_list"
                                        :key="index"
                                        :label="ct_type.type_name"
                                        :value="ct_type.id"
                                        auto-complete="nope"
                                      >
                                      </el-option>
                                    </el-select>
                                  </el-form-item>
                                </div>
                                <div class="col-sm-4">
                                  <el-form-item
                                    label="Part of Mailing List"
                                    prop="mailing_list"
                                  >
                                    <el-select
                                      ref="city"
                                      filterable
                                      v-model="form.mailing_list"
                                    >
                                      <el-option
                                        v-for="(p_mail, index) in part_mailing"
                                        :key="index"
                                        :label="p_mail.text"
                                        :value="p_mail.text"
                                      >
                                      </el-option>
                                    </el-select>
                                  </el-form-item>
                                </div>
                                <div class="col-xl-4">
                                  <el-form-item
                                    label="Job Category"
                                    prop="job_category"
                                  >
                                    <el-select
                                      filterable
                                      v-model="form.job_category"
                                      placeholder="Select"
                                      class="dropdown-add"
                                      clearable
                                    >
                                      <el-option class="add-container" value="">
                                        <a
                                          class="add-drop-btn"
                                          @click="CreateJob()"
                                        >
                                          <i class="el-icon-circle-plus"></i>
                                          Add
                                        </a>
                                      </el-option>
                                      <el-option
                                        v-for="(job, index) in job_list"
                                        :key="index"
                                        :label="job.job_cat_name"
                                        :value="job.job_cat_name"
                                        auto-complete="nope"
                                      >
                                      </el-option>
                                    </el-select>
                                  </el-form-item>
                                </div>
                                <div class="col-sm-4" v-if="!this.$route.params.customer_id">
                                <el-form-item
                                  label="Password"
                                  prop="password"
                                >
                                  <el-input
                                    v-model="form.customer_password"
                                    auto-complete="nope"
                                    show-password
                                    :class="
                                      form.errors.has('customer_password')
                                        ? 'error required'
                                        : 'required'
                                    "
                                    ></el-input>
                                </el-form-item>
                                <span
                                    class="form-err"
                                    v-if="form.errors.has('customer_password')"
                                    >{{ form.errors.get("customer_password") }}</span
                                  >
                              </div>
                                <div class="col-sm-4">
                                  <el-form-item
                                    label="Outside Sales"
                                    prop="mailing_list"
                                  >
                                    <el-select
                                      ref="out_sales"
                                      filterable
                                      v-model="form.outside_sales"
                                    >
                                      <el-option
                                        v-for="(out_sal, index) in out_list"
                                        :key="index"
                                        :label="out_sal.text"
                                        :value="out_sal.text"
                                      >
                                      </el-option>
                                    </el-select>
                                  </el-form-item>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-3">
                              <div class="form-group mt-6 mb-0">
                                <div class="field text-center">
                                  <div class="preview-container">
                                    <img
                                      v-if="img_preview"
                                      :src="img_preview"
                                    />
                                  </div>
                                </div>
                                <div class="field text-center">
                                  <div class="upload-btn-container">
                                    <a href="#" class="add-logo-btn">
                                      <span v-if="image_btn == 0"
                                        >Add Photo</span
                                      >
                                      <span v-else>Edit Photo</span>
                                      <input
                                        type="file"
                                        @change="onFileChange"
                                        accept="image/*"
                                      />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!--begin: Settings Actions -->
                          <div class="d-flex justify-content-end pt-5">
                            <button
                              @click="createCustomer"
                              v-if="this.$route.params.customer_id"
                              :disabled="disable_button"
                              type="button"
                              class="
                                mr-3
                                btn btn-sm btn-primary
                                font-weight-bold
                                text-uppercase
                              "
                            >
                              {{ submit_btn_text }}
                            </button>
                            <button
                              @click="gotoTab('shipping')"
                              :disabled="disable_button"
                              type="button"
                              class="
                                btn btn-sm btn-primary
                                font-weight-bold
                                text-uppercase
                              "
                            >
                              Next
                            </button>
                          </div>
                          <!--end: Settings Actions -->
                        </el-tab-pane>
                        <el-tab-pane label="Shipping & Billing" name="shipping">
                          <el-tabs v-if="this.$route.params.customer_id">
                            <el-tab-pane label="Billing">
                              <button
                                class="
                                  btn
                                  rounded-0
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                  btn-primary
                                  mb-4
                                "
                                @click.prevent="CreateAddress(1)"
                              >
                                <i class="fa fa-plus"></i>Add Address
                              </button>
                              <el-table
                                :data="billing_addresses_list"
                                element-loading-text="Loading"
                                stripe
                                border
                                fit
                              >
                                <el-table-column
                                  label="Address"
                                  prop="address_line_2"
                                  sortable
                                >
                                  <template slot-scope="scope">
                                    <span>{{ scope.row.address_line_2 }}</span>
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  align="center"
                                  label="Unit Number"
                                  prop="address_line_1"
                                  sortable
                                >
                                  <template slot-scope="scope">
                                    <span>{{ scope.row.address_line_1 }}</span>
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  label="Province"
                                  prop="state_name"
                                  sortable
                                >
                                  <template slot-scope="scope">
                                    {{
                                      scope.row.state_details
                                        ? scope.row.state_details.state_name
                                        : ""
                                    }}
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  label="City"
                                  prop="city_name"
                                  sortable
                                >
                                  <template slot-scope="scope">
                                    {{
                                      scope.row.city_details
                                        ? scope.row.city_details.city_name
                                        : ""
                                    }}
                                  </template>
                                </el-table-column>

                                <el-table-column
                                  label="Postal Code"
                                  prop="post_code"
                                  sortable
                                >
                                  <template slot-scope="scope">
                                    {{ scope.row.post_code }}
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  fixed="right"
                                  align="center"
                                  label="Actions"
                                  width="80"
                                >
                                  <template slot-scope="scope">
                                    <el-dropdown
                                      @command="
                                        handleActions($event, scope.row)
                                      "
                                      size="medium"
                                    >
                                      <i class="flaticon2-menu-1"></i>
                                      <el-dropdown-menu
                                        slot="dropdown"
                                        class="action-dropdown"
                                      >
                                        <el-dropdown-item :command="1"
                                          ><i class="fa fa-pen"></i
                                          >Edit</el-dropdown-item
                                        >
                                        <el-dropdown-item :command="2"
                                          ><i class="fa fa-trash"></i
                                          >Delete</el-dropdown-item
                                        >
                                      </el-dropdown-menu>
                                    </el-dropdown>
                                  </template>
                                </el-table-column>
                              </el-table>
                            </el-tab-pane>
                            <el-tab-pane label="Shipping">
                              <button
                                class="
                                  btn
                                  rounded-0
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                  btn-primary
                                  mb-4
                                "
                                @click.prevent="CreateAddress(2)"
                              >
                                <i class="fa fa-plus"></i>Add Address
                              </button>
                              <el-table
                                :data="shipping_addresses_list"
                                element-loading-text="Loading"
                                stripe
                                border
                                fit
                              >
                                <el-table-column
                                  label="Address"
                                  prop="address_line_2"
                                  sortable
                                >
                                  <template slot-scope="scope">
                                    <span>{{ scope.row.address_line_2 }}</span>
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  align="center"
                                  label="Unit Number"
                                  prop="address_line_1"
                                  sortable
                                >
                                  <template slot-scope="scope">
                                    <span v-if="scope.row.address_line_1">{{
                                      scope.row.address_line_1
                                    }}</span>
                                  </template>
                                </el-table-column>

                                <el-table-column
                                  label="Province"
                                  prop="state_name"
                                  sortable
                                >
                                  <template slot-scope="scope">
                                    {{
                                      scope.row.state_details
                                        ? scope.row.state_details.state_name
                                        : ""
                                    }}
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  label="City"
                                  prop="city_name"
                                  sortable
                                >
                                  <template slot-scope="scope">
                                    {{
                                      scope.row.city_details
                                        ? scope.row.city_details.city_name
                                        : ""
                                    }}
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  label="Postal Code"
                                  prop="post_code"
                                  sortable
                                >
                                  <template slot-scope="scope">
                                    {{ scope.row.post_code }}
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  fixed="right"
                                  align="center"
                                  label="Actions"
                                  width="80"
                                >
                                  <template slot-scope="scope">
                                    <el-dropdown
                                      @command="
                                        handleActions($event, scope.row)
                                      "
                                      size="medium"
                                    >
                                      <i class="flaticon2-menu-1"></i>
                                      <el-dropdown-menu
                                        slot="dropdown"
                                        class="action-dropdown"
                                      >
                                        <el-dropdown-item :command="1"
                                          ><i class="fa fa-pen"></i
                                          >Edit</el-dropdown-item
                                        >
                                        <el-dropdown-item :command="2"
                                          ><i class="fa fa-trash"></i
                                          >Delete</el-dropdown-item
                                        >
                                      </el-dropdown-menu>
                                    </el-dropdown>
                                  </template>
                                </el-table-column>
                              </el-table>
                            </el-tab-pane>
                          </el-tabs>
                          <div
                            class="row"
                            v-if="!this.$route.params.customer_id"
                          >
                            <!-- begin: billing address -->
                            <div class="col-xl-6">
                              <div class="row">
                                <div class="col-xl-12">
                                  <h4>Billing Address</h4>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <el-switch
                                        active-text="Same as Customer details"
                                        v-model="form.billing_status"
                                        @change="setBillingAddress()"
                                      ></el-switch>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-8">
                                      <el-form-item
                                        label="Address"
                                        prop="address_line_2"
                                      >
                                        <vue-google-autocomplete
                                          ref="autocomplete1"
                                          v-model="
                                            form.billing_addresses.address_line_2"
                                          id="map1"
                                          classname="form-control el-input__inner"
                                          name="billing_addresses.address_line_2"
                                          placeholder="Enter your Address"
                                          v-on:placechanged="
                                            getAddressBillingData
                                          "
                                          :disabled="
                                          form.billing_status ? true : false
                                          "
                                          country="ca"
                                        >
                                        </vue-google-autocomplete>
                                      </el-form-item>
                                      <span
                                        class="form-err"
                                        v-if="
                                          form.errors.has(
                                            'billing_addresses.address_line_2'
                                          )
                                        "
                                        >Address field is required.</span
                                      >
                                    </div>
                                    <div class="col-sm-4">
                                      <el-form-item
                                        label="Unit Number"
                                        prop="address_line_1"
                                      >
                                        <el-input
                                          v-model="
                                            form.billing_addresses
                                              .address_line_1
                                          "
                                          @input.native="capitalize"
                                          auto-complete="nope"
                                          :disabled="
                                            form.billing_status ? true : false
                                          "
                                        ></el-input>
                                      </el-form-item>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-4">
                                      <el-form-item
                                        label="Province"
                                        prop="state"
                                      >
                                        <el-select
                                          ref="state"
                                          filterable
                                          clearable
                                          v-model="form.billing_addresses.state"
                                          @change="
                                            getCityListByStateId('billing')
                                          "
                                          :disabled="
                                            form.billing_status ? true : false
                                          "
                                        >
                                          <el-option
                                            v-for="(state, index) in state_list"
                                            :key="index"
                                            :label="state.state_name"
                                            :value="state.state_id"
                                          >
                                          </el-option>
                                        </el-select>
                                        <span class="red">{{
                                          billing_state
                                        }}</span>
                                      </el-form-item>
                                    </div>
                                    <div class="col-sm-4">
                                      <el-form-item label="City" prop="city">
                                        <el-select
                                          ref="city"
                                          filterable
                                          clearable
                                          v-model="form.billing_addresses.city"
                                          :disabled="
                                            form.billing_status ? true : false
                                          "
                                        >
                                          <el-option
                                            v-for="(
                                              city, index
                                            ) in city_billing_list"
                                            :key="index"
                                            :label="city.city_name"
                                            :value="city.city_id"
                                          >
                                          </el-option>
                                        </el-select>
                                        <span class="red">{{
                                          billing_city
                                        }}</span>
                                      </el-form-item>
                                    </div>
                                    <div class="col-sm-4">
                                      <el-form-item
                                        label="Postal Code"
                                        prop="post_code"
                                      >
                                        <el-input
                                          v-model="
                                            form.billing_addresses.post_code
                                          "
                                          auto-complete="nope"
                                          placeholder="M1S 3A7"
                                          :disabled="
                                            form.billing_status ? true : false
                                          "
                                          @change="
                                            billingPostal(
                                              form.billing_addresses.post_code
                                            )
                                          "
                                          :maxlength="7"
                                        ></el-input>
                                        <span class="red">{{
                                          billing_postal
                                        }}</span>
                                      </el-form-item>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- end: billing address -->
                            <!-- begin: shipping address -->
                            <div class="col-xl-6">
                              <div class="row">
                                <div class="col-xl-12">
                                  <h4>Shipping Address</h4>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-sm-12">
                                  <div class="row">
                                    <div class="col-sm-12">
                                      <el-switch
                                        active-text="Same as Billing address"
                                        v-model="form.shipping_status"
                                        @change="setShippingAddress()"
                                      ></el-switch>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-8">
                                      <el-form-item
                                        label="Address"
                                        prop="address_line_2"
                                      >
                                        <vue-google-autocomplete
                                          ref="autocomplete"
                                          v-model="
                                            form.shipping_addresses.address_line_2
                                          "
                                          id="map2"
                                          classname="form-control el-input__inner"
                                          name="shipping_addresses.address_line_2"
                                          placeholder="Enter your Address"
                                          v-on:placechanged="
                                            getAddressShippingData
                                          " :disabled="
                                          form.shipping_status ? true : false
                                          "
                                          country="ca"
                                        >
                                        </vue-google-autocomplete>
                                      </el-form-item>
                                      <span
                                        class="form-err"
                                        v-if="
                                          form.errors.has(
                                            'shipping_addresses.address_line_2'
                                          )
                                        "
                                        >Address field required.</span
                                      >
                                    </div>
                                    <div class="col-sm-4">
                                      <el-form-item
                                        label="Unit Number"
                                        prop="address_line_1"
                                      >
                                        <el-input
                                          v-model="
                                            form.shipping_addresses
                                              .address_line_1
                                          "
                                          @input.native="capitalize"
                                          auto-complete="nope"
                                          :disabled="
                                            form.shipping_status ? true : false
                                          "
                                        ></el-input>
                                      </el-form-item>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-sm-4">
                                      <el-form-item
                                        label="Province"
                                        prop="state"
                                      >
                                        <el-select
                                          ref="state"
                                          clearable
                                          filterable
                                          v-model="
                                            form.shipping_addresses.state
                                          "
                                          @change="
                                            getCityListByStateId('shipping')
                                          "
                                          :disabled="
                                            form.shipping_status ? true : false
                                          "
                                        >
                                          <el-option
                                            v-for="(state, index) in state_list"
                                            :key="index"
                                            :label="state.state_name"
                                            :value="state.state_id"
                                          >
                                          </el-option>
                                        </el-select>
                                        <span class="red">{{
                                          shipping_state
                                        }}</span>
                                      </el-form-item>
                                    </div>
                                    <div class="col-sm-4">
                                      <el-form-item
                                        label="City"
                                        prop="city"
                                        v-loading="city_loading"
                                      >
                                        <el-select
                                          ref="city"
                                          filterable
                                          clearable
                                          v-model="form.shipping_addresses.city"
                                          :disabled="
                                            form.shipping_status ? true : false
                                          "
                                        >
                                          <el-option
                                            v-for="(
                                              city, index
                                            ) in city_shipping_list"
                                            :key="index"
                                            :label="city.city_name"
                                            :value="city.city_id"
                                          >
                                          </el-option>
                                        </el-select>
                                        <span class="red">{{
                                          shipping_city
                                        }}</span>
                                      </el-form-item>
                                    </div>
                                    <div class="col-sm-4">
                                      <el-form-item
                                        label="Postal Code"
                                        prop="post_code"
                                      >
                                        <el-input
                                          v-model="
                                            form.shipping_addresses.post_code
                                          "
                                          auto-complete="nope"
                                          :disabled="
                                            form.shipping_status ? true : false
                                          "
                                          placeholder="M1S 3A7"
                                          @change="
                                            shippingPostal(
                                              form.shipping_addresses.post_code
                                            )
                                          "
                                          :maxlength="7"
                                        ></el-input>
                                        <span class="red">{{
                                          shipping_postal
                                        }}</span>
                                      </el-form-item>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- end: shipping address -->
                          </div>
                          <div class="d-flex justify-content-end pt-5">
                            <a
                              @click="gotoTab('customer_details')"
                              class="text-white mr-3"
                            >
                              <div
                                class="
                                  btn btn-sm btn-warning
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                Back
                              </div>
                            </a>
                            <a
                              @click="createCustomer"
                              class="text-white mr-3"
                              v-if="!this.$route.params.customer_id"
                            >
                              <div
                                class="
                                  btn btn-sm btn-success
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                {{ submit_btn_text }}
                              </div>
                            </a>
                            <a
                              @click="gotoTab('credits')"
                              class="text-white"
                              v-if="credit_access != 0"
                            >
                              <div
                                class="
                                  btn btn-sm btn-primary
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                Next
                              </div>
                            </a>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane
                          label="Customer Credits"
                          name="credits"
                          :disabled="credit_access == 0"
                        >
                          <div class="row">
                            <div class="col-xl-4">
                              <el-form-item label="Authorized Person">
                                <el-select
                                  filterable
                                  clearable
                                  v-model="form.user_id"
                                  placeholder="Select"
                                  style="width: 100%"
                                  @clear="userClear"
                                >
                                  <el-option
                                    v-for="(user, index) in users"
                                    :key="index"
                                    :label="
                                      user.first_name +
                                      ' ' +
                                      (user.last_name == null
                                        ? ''
                                        : user.last_name)
                                    "
                                    :value="user.id"
                                    auto-complete="off"
                                  >
                                  </el-option>
                                </el-select>
                                <span class="form-err" v-if="user_error">{{
                                  user_error
                                }}</span>
                              </el-form-item>
                              <el-form-item
                                label="Credit Limit"
                                prop="credit_limit"
                              >
                                <el-input
                                  v-model="form.credit_limit"
                                  prefix-icon="fas fa-dollar-sign"
                                  auto-complete="off"
                                  :min="0"
                                  @change="checkAmount(form.credit_limit)"
                                ></el-input>
                                <span class="form-err" v-if="credit_error">{{
                                  credit_error
                                }}</span>
                              </el-form-item>
                              <el-form-item label="Document Name">
                                <template>
                                  <el-select
                                    filterable
                                    multiple
                                    collapse-tags
                                    v-model="form.document_name"
                                    placeholder="Select"
                                    style="width: 100%"
                                    @change="addDocuments"
                                    @clear="documentClear"
                                  >
                                  <el-option class="add-container" value="">
                                  <a
                                    class="add-drop-btn"
                                    @click="CreateDocument()"
                                  >
                                    <i class="el-icon-circle-plus"></i>
                                    Add Document
                                  </a>
                                  </el-option>
                                    <el-option
                                      v-for="(docs, index) in document_list"
                                      :key="index"
                                      :label="docs.document_name"
                                      :value="docs.document_name"
                                      auto-complete="off"
                                      style="height: auto"
                                    >
                                    </el-option>
                                  </el-select>
                                </template>
                                <span class="form-err" v-if="document_error">{{
                                  document_error
                                }}</span>
                              </el-form-item>

                              <el-form-item prop="term_id">
                                <el-checkbox
                                  v-model="form.credit_approve"
                                  :checked="form.credit_approve"
                                  >Credit Term</el-checkbox
                                >
                                <el-select
                                  v-model="form.term_id"
                                  placeholder="Select"
                                  clearable
                                  v-if="form.credit_approve == true"
                                >
                                  <el-option class="add-container" value="">
                                    <a
                                      class="add-drop-btn"
                                      @click="CreateTerm()"
                                    >
                                      <i class="el-icon-circle-plus"></i> Add
                                      Term
                                    </a>
                                  </el-option>
                                  <el-option
                                    v-for="(term, index) in term_list"
                                    :key="index"
                                    :label="term.term_name + ' days'"
                                    :value="term.id"
                                    auto-complete="off"
                                  >
                                  </el-option>
                                </el-select>
                                <span
                                  class="form-err"
                                  v-if="
                                    creditTerm_error ||
                                    form.credit_approve == true
                                  "
                                  >{{ creditTerm_error }}</span
                                >
                              </el-form-item>
                            </div>
                            <div class="col-sm-8" style="text-align:center;">
                            <div class="row">
                              <div class="col-xl-4 mt-6" v-for="(document,index) in form.upload_document" :key="index">
                                <div class="field">
                                  <div class="preview-container">
                                    <img
                                      :src="document.document_image"
                                      style="width: 150px; height: 200px"
                                    />
                                  </div>
                                </div>
                                <div class="field">
                                  <div
                                    class="upload-btn-container"
                                    style="margin-top: 0px"
                                  >
                                    <a
                                      href="#"
                                      class="add-logo-btn"
                                      style=" display: inline !important;margin-left: 14px;"
                                    >
                                      Add Document
                                      <input
                                        type="file"
                                        @change="onDocFileChange($event, document.document_name)"
                                        accept="image/gif, image/jpeg"
                                      />
                                    </a>
                                    <div class="document_name">
                                      {{document.document_name}}
                                    </div>
                                  </div>
                                </div>
                                <span class="form-err" v-if="document.document_error == false" style="text-align:center">*document required.</span>
                              </div>                      
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <!-- <div class="col-xl-8">
                            <CreditView :credit_id="form.credit_id"></CreditView>
                          </div> -->
                          </div>
                          <div class="d-flex justify-content-end pt-5">
                            <a
                              @click="gotoTab('shipping')"
                              class="text-white mr-3"
                            >
                              <div
                                class="
                                  btn btn-sm btn-warning
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                Back
                              </div>
                            </a>
                            <a @click="createCustomer" class="text-white">
                              <div
                                class="
                                  btn btn-sm btn-success
                                  font-weight-bold
                                  text-uppercase
                                "
                              >
                                {{ submit_btn_text }}
                              </div>
                            </a>
                          </div>
                        </el-tab-pane>
                      </el-tabs>
                    </div>
                  </div>
                </div>
              </el-form>
              <!--end: Settings Form-->
            </div>
          </div>
          <!--end: Settings Body-->
        </div>
      </div>
      <!--end: Settings-->
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from "@/core/services/api.form.services";
import {
  getCustomer,
  getCustomerType,
  getDocumentList,
  deleteAddress,
  getCustomerJob,
  getPhoneCheck,
  getEmailCheck,
  generateCustomerId,
} from "@/api/customer";
import { getAllActiveSites } from "@/api/website";
import {
  getCityList,
  getStateList,
  getCityListByStateId,
  getStateByPrefix,
  getCityByName,
} from "@/api/common";
import { getUsers, getAllUsers } from "@/api/customer";
import CreateType from "@/components/CreateCustomer/add_type";
import CreateDocument from "@/components/CreateCustomer/document_type";
import { globalEvents } from "@/utils/globalEvents";
import UpdateAddress from "@/components/CreateAddress";
import CreateAddress from "@/components/CreateAddress/addAddress";
import JwtService from "@/core/services/jwt.service";
import CreateJobCategory from "@/components/DropDown/jobCategory";
import CreateCity from "@/components/DropDown/addcity";
import { getOrderTerm } from "@/api/order";
import CreateTerm from "@/components/CreateOrderTerm";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "add_edit_customer",
  props: {
    label: {
      type: Object,
      default() {
        return { button: "Create", title: "Create Customer" };
      },
    },
  },
  data() {
    return {
      loading: false,
      email_loading: false,
      city_loading: false,
      add_credit: false,
      activeTab: "customer_details",
      credit_access: null,
      user_id: null,
      styleObject: {
        border: "1px solid red",
        borderRadius: "4px",
      },
      img_url: process.env.VUE_APP_BASE_IMAGE_URL + "/credit/",
      form: new Form({
        age: "",
        mailing_list: "Yes",
        first_name: null,
        last_name: null,
        customer_id: null,
        email_address: null,
        customer_mobile: null,
        customer_phone: null,
        website_id: 20,
        available_credit: 0,
        balance_showing: 0,
        credit_limit: null,
        active: "Active",
        job_category: null,
        profile_image: null,
        document_name: null,
        company_name: null,
        customer_fax: null,
        shipping_status: 0,
        billing_status: 0,
        document_license: null,
        document_passport: null,
        document_health: null,
        document_other: null,
        type_id: 1,
        user_id: null,
        unit_no: null,
        street: null,
        city_id: null,
        state_id: 8,
        post_code: null,
        latitude:null,
        longitude : null,
        outside_sales: "No",
        customer_password: "6(WdLQ5ikxjl",
        credit_approve: false,
        term_id: null,
        billing_addresses: {
          address_line_1: null,
          address_line_2: null,
          city: null,
          state: null,
          post_code: null,
          type: "billing",
          latitude:null,
          longitude : null,
        },
        shipping_addresses: {
          address_line_1: null,
          address_line_2: null,
          city: null,
          state: null,
          post_code: null,
          latitude:null,
          longitude : null,
          type: "shipping",
        },
        documents: [],
        upload_document:[],
        credit_id: 0,
      }),
      city_billing_list: [],
      city_shipping_list: [],
      state_list: [],
      active_website: [],
      address: [],
      credit: [],
      users: [],
      user_list: [],
      type_list: [],
      city_list: [],
      document_list: [],
      document: [],
      job_list: [],
      shipping_city: null,
      billing_city: null,
      shipping_state: null,
      billing_state: null,
      shipping_postal: null,
      billing_postal: null,
      billing_addresses_list: [],
      shipping_addresses_list: [],
      part_mailing: [
        { id: 1, text: "Yes" },
        { id: 0, text: "No" },
      ],
      out_list: [
        { id: 1, text: "Yes" },
        { id: 0, text: "No" },
      ],
      profile_btn_text: null,
      submit_btn_text: "Save",
      title_text: "Add Customer",
      api_url: "/customer/create",
      response_msg: "Customer created successfully.",
      img_preview: "/./media/bg/profile-img.png",
      doc_preview: "/./media/bg/profile-img.png",
      document_license: "/./media/bg/profile-img.png",
      document_passport: "/./media/bg/profile-img.png",
      document_health: "/./media/bg/profile-img.png",
      document_other: "/./media/bg/profile-img.png",
      document_name1: "",
      document_name2: "",
      document_name3: "",
      document_name4: "",
      options: [
        { id: 1, text: "Active" },
        { id: 0, text: "In Active" },
      ],
      term_list: [],
      user_error: "",
      credit_error: "",
      document_error: "",
      phone_error: "",
      document1_error: "",
      document2_error: "",
      document3_error: "",
      document4_error: "",
      phone1_error: "",
      email_error: "",
      disable_button: false,
      creditTerm_error: "",
      credit_document_error: false,
      image_btn: 0,
    };
  },
  components: {
    VueGoogleAutocomplete,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Customers", route: "/customer/list" },
      { title: "Add/Edit Customer" },
    ]);

    globalEvents.$on("TypeAdded", (type) => {
      if (type) {
        this.type_list.push(type);
        this.form.type_id = type.id;
      }
    });
    globalEvents.$on("cityname", (type) => {
      if (type) {
        this.city_list.push(type);
        this.form.city_id = type.city_id;
      }
    });
    globalEvents.$on("DocumentType", (type) => {
      if (type) {
        this.document_list.push(type);
        this.form.document_name.push(type.document_name);
        this.addDocuments();
      }
    });
    globalEvents.$on("AddressAdded", (address) => {
      if (address) {
        this.getCustomer(address.customer_id);
      }
    });
    globalEvents.$on("jobCategory", (job_cat) => {
      if (job_cat) {
        this.job_list.push(job_cat);
        this.form.job_category = job_cat.job_cat_name;
      }
    });
    globalEvents.$on("TermAdded", (term) => {
      if (term) {
        this.term_list.push(term);
        this.form.term_id = term.term_name;
      }
    });
  },
  watch: {
    search(value) {
      if (!value) {
        return;
      }
      let state_id = null;
      let address_type = value;
      if (address_type == "billing") {
        state_id = this.form.billing_addresses.state;
      } else if (address_type == "shipping") {
        state_id = this.form.shipping_addresses.state;
      } else if (address_type == "primary") {
        state_id = this.form.state_id;
      }
      getCityListByStateId(state_id).then((response) => {
        if (address_type == "billing") {
          this.form.billing_addresses.city = null;
          this.city_billing_list = response.data.data;
        } else if (address_type == "shipping") {
          this.form.shipping_addresses.city = null;
          this.city_shipping_list = response.data.data;
        }
      });
    },
  },

  methods: {
    closeForm() {
      if (!this.form.submiting) {
        this.$emit("close");
      }
    },
    disableButton() {
      this.disable_button = true;
    },
    getAddressData: function (addressData) {
      this.form.post_code = addressData.postal_code;
      this.form.latitude = addressData.latitude
      this.form.longitude = addressData.longitude
      var city_name = addressData.locality;
      if (city_name != null) {
        this.getCityIdByName(city_name, "0");
      }
      var state_prefix = addressData.administrative_area_level_1;
      if (state_prefix != null) {
        this.getStateByStatePrefix(state_prefix, "0");
      }
      console.log(addressData);
        var street = "";
      if (addressData.street_number != null) {
        street = addressData.street_number;
      }

      this.form.street = street+ " " + addressData.route;
      this.address = addressData;
    },
    getAddressBillingData: function (addressData) {
      this.form.billing_addresses.post_code = addressData.postal_code;
      this.form.billing_addresses.latitude = addressData.latitude
      this.form.billing_addresses.longitude = addressData.longitude
      var city_name = addressData.locality;
      if (city_name != null) {
        this.getCityIdByName(city_name, "1");
      }
      var state_prefix = addressData.administrative_area_level_1;
      if (state_prefix != null) {
        this.getStateByStatePrefix(state_prefix, "1");
      }

      var street = "";
      if (addressData.street_number != null) {
        street = addressData.street_number;
      }

      this.form.billing_addresses.address_line_2 =
        street + " " + addressData.route;
      this.address = addressData;

      if(this.form.shipping_status == true){
        this.form.shipping_addresses.state = '';
        this.form.shipping_addresses.city = '';
        this.setShippingAddress();
      
      }
    },
    getAddressShippingData: function (addressData) {
      this.form.shipping_addresses.post_code = addressData.postal_code;
      this.form.shipping_addresses.latitude = addressData.latitude
      this.form.shipping_addresses.longitude = addressData.longitude
      var city_name = addressData.locality;
      if (city_name != null) {
        this.getCityIdByName(city_name, "2");
      }
      var state_prefix = addressData.administrative_area_level_1;
      if (state_prefix != null) {
        this.getStateByStatePrefix(state_prefix, "2");
      }
      console.log(addressData);

      var street = "";
      if (addressData.street_number != null) {
        street = addressData.street_number;
      }

      this.form.shipping_addresses.address_line_2 =
        street + " " + addressData.route;
      this.address = addressData;
    },
    getStateByStatePrefix(state_prefix, type) {
      getStateByPrefix(state_prefix).then((response) => {
        if (type == "0") {
          this.form.state_id = response.data.data.state_id;
        } else if (type == "1") {
          this.form.billing_addresses.state = response.data.data.state_id;
        } else {
          this.form.shipping_addresses.state = response.data.data.state_id;
        }
      });
    },
    getCityIdByName(city_name, type) {
      getCityByName(city_name).then((response) => {
        if (type == "0") {
          const city_id = response.data.data.city_id;
          const stateId = response.data.data.state_id;
          getCityListByStateId(stateId).then((response) => {
            this.city_list = response.data.data;
            this.form.city_id = city_id;
            this.city_loading = false;
          });
        } else if (type == "1") {
          const billingStateId = response.data.data.state_id;
          const billingCityId = response.data.data.city_id;
          getCityListByStateId(billingStateId).then((response) => {
            this.city_billing_list = response.data.data;
            this.form.billing_addresses.city = billingCityId;
            this.city_loading = false;
          });
        } else {
          const ShippingStateId = response.data.data.state_id;
          const ShippingCityId = response.data.data.city_id;
          getCityListByStateId(ShippingStateId).then((response) => {
            this.city_shipping_list = response.data.data;
            this.form.shipping_addresses.city = ShippingCityId;
            this.city_loading = false;
          });
        }
      });
    },
    generateCustomerId() {
      var storeid = this.form.website_id;
      generateCustomerId(storeid).then((response) => {
        this.form.customer_id = response.data.data;
      });
    },
    getTermlist() {
      getOrderTerm().then((response) => {
        this.term_list = response.data.data;
      });
    },
    userClear() {
      this.form.user_id = "";
    },
    addDocuments() {
      this.form.upload_document = [];
      this.form.document_name.map((doc)=>{
        if(doc){
        this.form.upload_document.push({
          'document_name' : doc,
          'document_image': '../../media/bg/profile-img.png',
          'image' :'',
          'document_error' : false
        })
      }
      });     
    },

    formatPhoneNumber(phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, match[2], "-", match[3], "-", match[4]].join("");
      }
      return null;
    },

    CreateAddress(val) {
      var titleName = "Shipping Address";
      if (val == "1") {
        titleName = "Billing Address";
      }
      this.$modal.show(
        CreateAddress,
        {
          customer_id: parseInt(this.$route.params.customer_id),
          address_type: val,
          label: {
            button: "Create",
            title: titleName,
          },
        },
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "600px",
        }
      );
    },

    CreateCity() {
      this.$modal.show(
        CreateCity,
        { stateId: this.form.state_id },
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    mobileNumberValidation: function (event) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.preventNextIteration = false;
        return;
      }
      this.phoneValue = this.form.customer_mobile.replace(/[^0-9]+/g, "");

      this.form.customer_mobile = this.phoneValue
        .replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "$1-$2-$3")
        .slice(0, 12);
      this.phone_error = "";
      this.getPhoneCheck(this.form.customer_mobile);
    },
    phoneNumberValidation: function (event) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.preventNextIteration = false;
        return;
      }
      this.phoneValue = this.form.customer_phone.replace(/[^0-9]+/g, "");

      this.form.customer_phone = this.phoneValue
        .replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "$1-$2-$3")
        .slice(0, 12);
      this.phone1_error = "";
      this.getPhoneCheck1(this.form.customer_phone);
    },
    checkZip(value) {
      return /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(value);
    },
    validPostal(postalcode) {
      let postal_code = postalcode.toUpperCase();
      if (this.checkZip(postal_code)) {
        this.form.post_code = postal_code
          .replace(/\W/g, "")
          .replace(/(...)/, "$1 ");
      } else {
        const h = this.$createElement;
        this.$notify({
          message: h(
            "i",
            {
              style: "color: red",
            },
            "Invalid Postal Code"
          ),
          offset: 200,
          type: "warning",
        });
        this.form.post_code = "";
      }
    },
    billingPostal(postalcode) {
      let postal_code = postalcode.toUpperCase();
      if (this.checkZip(postal_code)) {
        this.form.billing_addresses.post_code = postal_code
          .replace(/\W/g, "")
          .replace(/(...)/, "$1 ");
      } else {
        const h = this.$createElement;
        this.$notify({
          message: h(
            "i",
            {
              style: "color: red",
            },
            "Invalid Postal Code"
          ),
          offset: 200,
          type: "warning",
        });
        this.form.billing_addresses.post_code = "";
      }
    },
    shippingPostal(postalcode) {
      let postal_code = postalcode.toUpperCase();
      if (this.checkZip(postal_code)) {
        this.form.shipping_addresses.post_code = postal_code
          .replace(/\W/g, "")
          .replace(/(...)/, "$1 ");
      } else {
        const h = this.$createElement;
        this.$notify({
          message: h(
            "i",
            {
              style: "color: red",
            },
            "Invalid Postal Code"
          ),
          offset: 200,
          type: "warning",
        });
        this.form.shipping_addresses.post_code = "";
      }
    },
    getEmailCheck(email) {
      this.email_loading = true;
      getEmailCheck(email)
        .then((response) => {
          this.email_error = "";
          this.email_loading = false;
          if (response.status === true) {
            return;
          }
        })
        .catch((error) => {
          this.email_error = error.data.error.email_address[0];
          this.form.errors.add(
            "email_address",
            error.data.error.email_address[0]
          );
          this.email_loading = false;
          this.email_error = error.data.error.email_address[0];
        });
    },
    getPhoneCheck(phone) {
      this.email_loading = true;
      let params = {};
      if (this.$route.params.customer_id)
        params.customer_id = this.$route.params.customer_id;
      getPhoneCheck(phone, params)
        .then((response) => {
          this.disable_button = false;
          this.phone_error = "";
          this.email_loading = false;
          if (response.status === true) {
            return;
          }
        })
        .catch((error) => {
          this.disable_button = true;
          if (error.data.error.customer_mobile[0]) {
            this.phone_error = "The phone Number has already been taken";
            this.activeTab = "customer_details";
          }
          //this.phone_error = error.data.error.customer_mobile[0];
        });
    },

    getPhoneCheck1(phone) {
      this.email_loading = true;
      let params = {};
      if (this.$route.params.customer_id)
        params.customer_id = this.$route.params.customer_id;
      getPhoneCheck(phone, params)
        .then((response) => {
          this.disable_button = false;
          this.phone1_error = "";
          this.email_loading = false;
          if (response.status === true) {
            return;
          }
        })
        .catch((error) => {
          console.log(error);
          this.disable_button = true;
          if (error.data.error.customer_mobile[0]) {
            this.phone1_error = "The phone Number has already been taken";
            this.activeTab = "customer_details";
          }
          //this.phone_error = error.data.error.customer_mobile[0];
        });
    },
    documentClear() {},
    gotoTab(tabname) {
      this.loading = true;
      if (tabname == "shipping") {
        if (
          !this.form.first_name ||
          !this.form.customer_mobile ||
          !this.form.website_id ||
          !this.form.street ||
          !this.form.state_id ||
          !this.form.city_id ||
          !this.form.post_code ||
          this.email_error ||
          this.phone_error ||
          this.phone1_error
        ) {
          this.form.errors.clear();
          this.isFormInValid = true;
          if (!this.form.first_name) {
            this.form.errors.add("first_name", "The First Name is required.");
          }
          if (!this.form.customer_mobile) {
            this.form.errors.add(
              "customer_mobile",
              "The Phone Number is required."
            );
          }
          if (!this.form.website_id) {
            this.form.errors.add("website_id", "The Store is required.");
          }
          if (!this.form.street) {
            this.form.errors.add("street", "The Address is required.");
          }

          if (!this.form.state_id) {
            this.form.errors.add("state_id", "The State is required.");
          }
          if (!this.form.city_id) {
            this.form.errors.add("city_id", "The City is required.");
          }
          if (!this.form.post_code) {
            this.form.errors.add("post_code", "The Postal Code is required.");
          }
        } else {
          if (this.shipping_address_status == 1) {
            this.form.shipping_status = false;
            this.form.billing_status = true;
          } else {
            this.form.billing_status = true;
            this.form.shipping_status = true;
          }

          if (this.billing_address_status == 1) {
            this.form.billing_status = false;
          }

          if (!this.$route.params.customer_id) {
            this.setBillingAddress();
            this.setShippingAddress();
          }
          this.activeTab = tabname;
          this.isFormInValid = false;
        }
      } else {
        this.form.errors.clear();
        this.activeTab = tabname;
        this.isFormInValid = false;
        this.loading = false;
      }

      this.loading = false;
    },
    handleTabClick(tab, event) {
      console.log(event);
      this.activeTab = tab;
    },

    onChangeDocuments(event) {
      this.form.documents = [];
      let i = 0;
      for (i = 0; i < event.length; i++) {
        this.form.documents.push({
          name: event[i],
          image: "/ctshub/media/bg/profile-img.png",
        });
      }
    },
    setShippingAddress() {
      if (this.form.shipping_status) {
        this.shipping_address_status = 0;
        this.$refs.autocomplete.clear();

        this.form.shipping_addresses.address_line_1 =
          this.form.billing_addresses.address_line_1;

        this.form.shipping_addresses.address_line_2 =
          this.form.billing_addresses.address_line_1;
        this.form.shipping_addresses.latitude =this.form.billing_addresses.latitude;
        this.form.shipping_addresses.longitude =this.form.billing_addresses.longitude;

        this.$refs.autocomplete.update(
          this.form.billing_addresses.address_line_2
        );        
       
        this.form.shipping_addresses.state = this.form.billing_addresses.state;

         getCityListByStateId(this.form.shipping_addresses.state).then(
          (response) => {
            console.log(this.form.billing_addresses);
            this.city_shipping_list = response.data.data;
            if (this.form.billing_addresses.city != "") {
              this.form.shipping_addresses.city =
                this.form.billing_addresses.city;
            } else {
              this.form.shipping_addresses.city = this.form.city_id;
            }
          }
        );

        this.form.shipping_addresses.post_code =
          this.form.billing_addresses.post_code;
      } else {
        
          this.$refs.autocomplete.clear();
          this.form.shipping_addresses.address_line_1 = null;
          this.form.shipping_addresses.address_line_2 = null;
          this.form.shipping_addresses.city = null;
          this.form.shipping_addresses.state = null;
          this.form.shipping_addresses.post_code = null;        
          this.form.shipping_addresses.latitude = null;
          this.form.shipping_addresses.longitude = null;
          this.shipping_address_status = 1;
      }
      this.form.shipping_addresses.type = "shipping";
    },
    setBillingAddress() {
      if (this.form.billing_status) {
        this.form.billing_addresses.address_line_1 = this.form.unit_no;
        this.form.billing_addresses.address_line_2 = this.form.street;
        this.form.billing_addresses.latitude = this.form.latitude;
        this.form.billing_addresses.longitude = this.form.longitude;
        this.$refs.autocomplete1.update(this.form.street);
        //this.form.billing_addresses.city = this.form.city_id;
        this.form.billing_addresses.state = this.form.state_id;
        getCityListByStateId(this.form.billing_addresses.state).then(
          (response) => {
            this.city_billing_list = response.data.data;
            this.form.billing_addresses.city = this.form.city_id;
          }
        );

        
        this.form.billing_addresses.post_code = this.form.post_code;
      } else {
       this.$refs.autocomplete1.clear();
        this.form.billing_addresses.address_line_1 = null;
        this.form.billing_addresses.address_line_2 = null;
        this.form.billing_addresses.city = null;
        this.form.billing_addresses.state = null;
        this.form.billing_addresses.post_code = null;
        this.form.billing_addresses.latitude = null;
        this.form.billing_addresses.longitude = null;
        this.form.shipping_status = false;
        this.setShippingAddress();
      }
      this.form.billing_addresses.type = "billing";
    },
    createCustomer: function () {
      this.loading = true;
      this.user_error = "";
      this.document_error = "";
      this.credit_error = "";
      this.phone_error = "";
      let upload_error = false;
      this.form.upload_document.map((document)=>{
        if(document.document_error == false){
          upload_error = true;
        }
      })
      if (this.form.user_id) {
        if (!this.form.credit_limit) {
          this.credit_error = "The credit field is required.";
        }
        if (this.form.document_name.length == 0) {
          this.document_error = "The document name field is required.";
        }
      }
      if (this.form.user_id && this.form.document_name.length > 0) {
        if (!this.form.credit_limit) {
          this.credit_error = "The credit field is required.";
        }
      }
      if (this.form.credit_limit) {
        if (!this.form.user_id) {
          this.user_error = "The Authorized Person field is required.";
        }
        if (this.form.document_name.length == 0) {
          this.document_error = "The document name field is required.";
        }
        this.loading = false;
      }
      if (!this.$route.params.customer_id) {
        if (!this.form.shipping_addresses.address_line_2) {
          this.form.shipping_addresses = [];
        } else {
          this.shipping_city = "";
          this.shipping_state = "";
          this.shipping_postal = "";
          if (!this.form.shipping_addresses.city) {
            this.shipping_city = "* field is required";
          }
          if (!this.form.shipping_addresses.state) {
            this.shipping_state = "* field is required";
          }
          if (!this.form.shipping_addresses.post_code) {
            this.shipping_postal = "* field is required";
          }
        }

        if (!this.form.billing_addresses.address_line_2) {
          // this.form.billing_addresses = [];
          this.form.billing_status = true;
          this.setBillingAddress();
        } else {
          this.billing_city = "";
          this.billing_state = "";
          this.billing_postal = "";
          if (!this.form.billing_addresses.city) {
            this.billing_city = "* field is required";
          }
          if (!this.form.billing_addresses.state) {
            this.billing_state = "* field is required";
          }
          if (!this.form.billing_addresses.post_code) {
            this.billing_postal = "* field is required";
          }
        }
        if (this.form.credit_approve) {
          this.creditTerm_error = "";
          if (!this.form.term_id) {
            this.creditTerm_error = "* Please select term";
            this.loading = false;
          }
        }
      }

      if (
        !this.document_error &&
        !this.credit_error &&
        !this.creditTerm_error &&
        this.credit_document_error == false &&
        upload_error == false
      ) {
        this.loading = true;
        this.form
          .post(this.api_url)
          .then((response) => {
            if (response.status) {
              globalEvents.$emit("CustomerAdded", response.data);
              this.$emit("close");
              this.loading = false;
              this.$showResponse("success", response.message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }
    },

    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (files.length <= 0) {
        return;
      }

      this.form.profile_image = files[0];

      this.img_preview = URL.createObjectURL(files[0]);
    },
    onDocFileChange(e, name) {
      let files = e.target.files || e.dataTransfer.files;
      if (files[0].size > 1024 * 1024) {
        e.preventDefault();
        const h = this.$createElement;
        this.$notify({
          message: h("i", { style: "color: red" }, "File too big (> 1MB)"),
          offset: 200,
          type: "warning",
        });

        return true;
      }
      if (files.length <= 0) {
        return;
      }
      this.form.upload_document.map(async(upload) =>{
        if(upload.document_name == name){
          upload.document_image = URL.createObjectURL(files[0]);
          upload.image = await this.readFile(files[0]);
          upload.document_error = true
        }
      })
    },
    readFile(file) {
      return new Promise((resolve) => {
        const fReader = new FileReader();
        fReader.onload = () => {
          resolve(fReader.result);
        };
        fReader.readAsDataURL(file);
      });
    },
    getCityListByStateId(address_type) {
      let state_id = null;

      if (address_type == "billing") {
        state_id = this.form.billing_addresses.state;
      } else if (address_type == "shipping") {
        state_id = this.form.shipping_addresses.state;
      } else if (address_type == "primary") {
        state_id = this.form.state_id;
      }
      getCityListByStateId(state_id).then((response) => {
        if (address_type == "billing") {
          this.form.billing_addresses.city = null;
          this.city_billing_list = response.data.data;
        } else if (address_type == "shipping") {
          this.form.shipping_addresses.city = null;
          this.city_shipping_list = response.data.data;
        }
      });
    },
    getAllActiveSites() {
      this.loading = true;
      getAllActiveSites().then((response) => {
        this.active_website = response.data.data;
        this.loading = false;
      });
    },
    checkAmount(amount) {
      this.credit_error = "";
      this.form.credit_approve = false;
      let due_amount = parseFloat(amount)
        .toFixed(2)
        .match(/^-?[0-9]*[.][0-9]+$/);
      if (due_amount != null) {
        this.form.credit_limit = parseFloat(due_amount).toFixed(2);
        this.form.credit_approve = true;
      } else {
        this.credit_error = "Please Enter Amount Only";
      }
    },
    getCityList() {
      this.loading = true;
      getCityList().then((response) => {
        this.city_billing_list = response.data.data;
        this.city_shipping_list = response.data.data;
        this.loading = false;
      });
    },
    getStateList() {
      this.loading = true;
      getStateList().then((response) => {
        this.state_list = response.data.data;
        this.loading = false;
      });
    },
    getCityListByState(state_id) {
      this.city_loading = true;
      getCityListByStateId(state_id).then((response) => {
        this.form.city_id = null;
        this.city_list = response.data.data;
        this.city_loading = false;
      });
    },
    getUsername() {
      this.loading = true;
      getUsers().then((response) => {
        this.users = response.data.data;
        this.loading = false;
      });
    },
    getType() {
      this.loading = true;
      getCustomerType().then((response) => {
        this.type_list = response.data.data;
        this.loading = false;
      });
    },
    getAllUsers() {
      this.loading = true;
      getAllUsers().then((response) => {
        this.user_list = response.data.data;
        this.user_list.map((user) => {
          if (user.id == this.user_id) {
            this.credit_access = user.credit_access;
          }
        });
        this.loading = false;
      });
    },
    getJobCategory() {
      this.loading = true;
      getCustomerJob().then((response) => {
        this.job_list = response.data.data;
        this.loading = false;
      });
    },
    getDocumentList() {
      this.loading = true;
      getDocumentList().then((response) => {
        this.document_list = response.data.data;
        this.loading = false;
      });
    },

    handleActions(evt, row) {
      if (evt === 1) {
        this.$modal.show(
          UpdateAddress,
          {
            customer_id: row.customer_id,
            address: row,
            label: {
              button: "Update",
              title: "Update Address",
            },
          },
          {
            draggable: true,
            transition: "pop-out",
            overflow: "auto",
            height: "auto",
            clickToClose: false,
            adaptive: true,
          }
        );
      } else if (evt === 2) {
        this.$bvModal
          .msgBoxConfirm("Are you sure you want to delete ?", {
            title: "Please Confirm",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.loading = true;
              deleteAddress(row.id).then((response) => {
                if (response.data.status) {
                  this.loading = false;
                  this.$showResponse("success", response.data.message);
                }
              });
            } else {
              this.loading = false;
              return false;
            }
          });
      }
    },
    CreateTerm() {
      this.$modal.show(
        CreateTerm,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    CreateType() {
      this.$modal.show(
        CreateType,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    CreateJob() {
      this.$modal.show(
        CreateJobCategory,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    CreateDocument() {
      this.$modal.show(
        CreateDocument,
        {},
        {
          draggable: true,
          transition: "pop-out",
          height: "auto",
          clickToClose: true,
          adaptive: true,
          width: "450px",
        }
      );
    },
    dataURItoBlob(dataURI) {
      var mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var binary = atob(dataURI.split(",")[1]);
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: mime });
    },
    getCustomer(customer_id) {
      getCustomer(customer_id).then((response) => {
        console.log(response);
        this.api_url = "customer/update/" + customer_id;
        this.form = new Form(response.data.data);
        this.form.last_name =
          this.form.last_name == null ? "" : this.form.last_name;
        let customer = response.data.data;
        this.form.credit_approve == "true" ? true : false;
        getCityListByStateId(customer.state_id).then((response) => {
          this.form.city_id = customer.city_id;
          this.city_list = response.data.data;
          this.loading = false;
        });
        this.shipping_addresses_list =
          response.data.data.customer_address.filter((address) => {
            return address.type == "shipping";
          });
        this.billing_addresses_list =
          response.data.data.customer_address.filter((address) => {
            return address.type == "billing";
          });

        if (customer.profile_image) {
          this.profile_btn_text = "Update";
          this.img_preview =
            process.env.VUE_APP_BASE_IMAGE_URL + customer.profile_image;
          this.image_btn = 1;
        }
        this.form.credit_id = 0;
        if (this.form.customer_credit) {
          this.form.user_id = this.form.customer_credit.user_id;
          this.form.credit_limit = this.form.customer_credit.credit_limit;
          this.form.document_name =
            this.form.customer_credit.document_name.split(",");
          this.form.credit_id = this.form.customer_credit.id;
          this.form.document_license =
            this.form.customer_credit.document_license;
          this.form.document_passport =
            this.form.customer_credit.document_passport;
          this.form.document_other = this.form.customer_credit.document_other;
          this.addDocuments(this.form.document_name);
          this.document_license = this.form.customer_credit.document_license
            ? process.env.VUE_APP_BASE_IMAGE_URL +
              "/credit/" +
              this.form.customer_credit.document_license
            : "/ctshub/media/bg/profile-img.png";
          this.document_passport = this.form.customer_credit.document_passport
            ? process.env.VUE_APP_BASE_IMAGE_URL +
              "/credit/" +
              this.form.customer_credit.document_passport
            : "/ctshub/media/bg/profile-img.png";
          this.document_health = this.form.customer_credit.document_health
            ? process.env.VUE_APP_BASE_IMAGE_URL +
              "/credit/" +
              this.form.customer_credit.document_health
            : "/ctshub/media/bg/profile-img.png";
          this.document_other = this.form.customer_credit.document_other
            ? process.env.VUE_APP_BASE_IMAGE_URL +
              "/credit/" +
              this.form.customer_credit.document_other
            : "/ctshub/media/bg/profile-img.png";
        }
      });
    },
  },
  created() {
    // console.log(this.$capitalize1('test'));
    let customer_id = this.$route.params.customer_id;
    this.profile_btn_text = "Add Image";

    if (parseInt(customer_id) > 0) {
      this.submit_btn_text = "Update";
      this.title_text = "Edit Customer";
      this.response_msg = "Customer updated successfully.";
      this.getCustomer(customer_id);
    } else {
      this.generateCustomerId();
    }

    this.getStateList();
    this.getCityListByState(this.form.state_id);
    this.getCityList();
    this.getAllActiveSites();
    if (JwtService.getAttribute()) {
      let updatedUserdata = JSON.parse(JwtService.getAttribute());
      this.user_id = updatedUserdata.user_id;

      //this.form.credit_access = updatedUserdata.first_name+' '+updatedUserdata.last_name
    }
    this.getUsername();
    this.getType();
    this.getAllUsers();
    this.getDocumentList();
    this.getJobCategory();
    this.getTermlist();
  },
};
</script>

<style>
.document_name {
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
.el-input-group__prepend {
  padding: 0px 8px !important;
}

.phone_check1 div .el-input-group--prepend {
  padding: 0px 8px !important;
}
.phone_check .el-input__inner {
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}
.phone_check .el-input-group__prepend {
  border-left: 2px solid red;
}

.phone_check_error .el-input-group__prepend {
  border-top: 1px solid red;
  border-bottom: 1px solid red;
}

.phone_check_error .el-input__inner {
  border-right: 1px solid red;
  border-top: 1px solid red;
  border-bottom: 1px solid red;
}
</style>
