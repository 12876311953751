<template>
<div>
  <div slot="header" class="modal-header">
			<span class="modal-title-popup">{{label.title}}</span>
			<i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
		</div>
     <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Employees-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Employees Body-->
                <div class="row ">
                    <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                         <el-form ref="form" :model="form" auto-complete="nope">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">                                
                                <div class="row">
                                  <div class="col-xl-8">                                    
                                      <el-form-item label="Tax Name" prop="cat_name">
                                            <el-input v-model="form.tax_name" @input.native="capitalize" auto-complete="nope" :class="form.errors.has('tax_name') ? 'error required':'required'"></el-input>
                                            <span class="form-err" v-if="form.errors.has('tax_name')" >{{form.errors.get('tax_name')}}</span>
                                        </el-form-item>                
                                    </div>
                                  </div>
                                </div>                                   
                                <div class="d-flex justify-content-between border-top pt-10">
                                  <a @click="createTax" class="text-white">
                                      <div class="btn btn-primary font-weight-bold text-uppercase ">
                                          Add 
                                      </div>
                                  </a>
                                </div>                               
                          </el-form>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>
</template>

<script>
import Form from '@/core/services/api.form.services'
import { globalEvents } from '@/utils/globalEvents'
export default {
  name: 'add-tax',
   props: {
  label:{
        type: Object,
        default() {
          return {button:'Create',title:'Create Tax'}
        }
      }
  },
    data() {
    return {
      loading:false,
      error:'',
      form : new Form({
       tax_name: null,
       
	  }),  
     
      submit_btn_text: 'Add Brand',
      title_text: 'Create Brand',
      api_url: '/create/tax',
      response_msg: 'Brand created successfully.',
    }
  },
  components: {},
  methods: {
     closeForm() {
    	if (!this.form.submiting) {
      	globalEvents.$emit('Category', false)
      	this.$emit('close')
    	}
	  },
    createTax: function(){
	  this.loading=true;
      this.form.post(this.api_url).then((response) => {         
        globalEvents.$emit('TaxName', response.data)
        this.$emit('close')

        if(response.status){
            this.loading=false;
			      this.$showResponse('success', response.message);
        }
      })
    }
  }

}
</script>
