<template>
  <div>
    <div slot="header" class="modal-header">
      <span class="modal-title-popup">{{ label.title }}</span>
      <i
        style="float: right; padding: 3px 0"
        class="model-close el-icon-close icon-pointer"
        @click.prevent="closeForm"
      ></i>
    </div>
    <div class="card card-custom custom-model-popup" v-loading="loading">
      <div class="card-body p-0">
        <el-form ref="form" :model="form" auto-complete="false">
          <div class="row">
            <div class="col-xl-12">
              <div class="row">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-8">
                      <el-form-item label="Street No & Street Name">
                        <vue-google-autocomplete
                          ref="origin_ad"
                          v-model="form.address_line_2"
                          id="mapp"
                          classname="form-control el-input__inner"
                          name="street"
                          placeholder="Enter your Address"
                          v-on:placechanged="getAddressData"
                          country="ca"
                        >
                        </vue-google-autocomplete>
                      </el-form-item>
                      <span
                        class="form-err"
                        v-if="form.errors.has('address_line_2')"
                        >Street No & Street Name is required.</span
                      >
                    </div>
                    <div class="col-sm-4">
                      <el-form-item label="Unit Number">
                        <el-input
                          v-model="form.address_line_1"
                          auto-complete="nope"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <el-form-item label="Province" prop="state">
                        <el-select
                          ref="state"
                          filterable
                          clearable
                          v-model="form.state"
                          @change="getCityListByStateId(form.type)"
                          :class="form.errors.has('state') ? 'error ' : ''"
                        >
                          <el-option
                            v-for="(state, index) in state_list"
                            :key="index"
                            :label="state.state_name"
                            :value="state.state_id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <span class="form-err" v-if="form.errors.has('state')"
                        >Province is required.</span
                      >
                    </div>
                    <div class="col-sm-4">
                      <el-form-item label="City" prop="city">
                        <el-select
                          filterable
                          clearable
                          ref="city"
                          v-model="form.city"
                          :class="form.errors.has('city') ? 'error ' : ''"
                        >
                          <el-option
                            v-for="(city, index) in city_list"
                            :key="index"
                            :label="city.city_name"
                            :value="city.city_id"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <span class="form-err" v-if="form.errors.has('city')">{{
                        form.errors.get("city")
                      }}</span>
                    </div>
                    <div class="col-sm-4">
                      <el-form-item label="Postal Code" prop="post_code">
                        <el-input
                          v-model="form.post_code"
                          auto-complete="nope"
                          maxlength="7"
                          @change="validPostal(form.post_code)"
                        ></el-input>
                      </el-form-item>
                      <span
                        class="form-err"
                        v-if="form.errors.has('post_code')"
                        >{{ form.errors.get("post_code") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top: 15px">
            <div class="col-sm-12 text-right">
              <button
                class="
                  rounded-0
                  btn btn-sm btn-primary
                  font-weight-bold
                  text-uppercase
                "
                @click.prevent="submitForm"
              >
                {{ label.button }}
              </button>
              <!-- <button class="btn btn-primary" @click.prevent="reset()" >Reset</button> -->
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "@/core/services/api.form.services";
import {
  getCityList,
  getStateList,
  getCityListByStateId,
  getStateByPrefix,
  getCityByName,
} from "@/api/common";
import { globalEvents } from "@/utils/globalEvents";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "add_edit_customer",
  props: {
    address: {
      type: Object,
      default() {
        return {};
      },
    },
    customer_id: {
      type: Number,
      required: true,
    },
    label: {
      type: Object,
      default() {
        return { button: "Create", title: "Add Address" };
      },
    },
  },
  data() {
    return {
      loading: false,
      form: new Form({
        address_line_1: null,
        address_line_2: null,
        city: null,
        state: null,
        post_code: null,
        type: null,
      }),
      city_list: [],
      state_list: [],
      submit_btn_text: "Create",
      title_text: "Add Address",
      api_url: "/customer/create",
      response_msg: "Address created successfully.",
    };
  },
  methods: {
    setShippingAddress() {
      if (this.form.shipping_status) {
        this.form.shipping_addresses.address_line_1 =
          this.form.billing_addresses.address_line_1;
        this.form.shipping_addresses.address_line_2 =
          this.form.billing_addresses.address_line_2;
        this.form.shipping_addresses.city = this.form.billing_addresses.city;
        this.form.shipping_addresses.state = this.form.billing_addresses.state;
        this.form.shipping_addresses.post_code =
          this.form.billing_addresses.post_code;
        this.form.shipping_addresses.type = "shipping";
      } else {
        this.form.shipping_addresses.address_line_1 = null;
        this.form.shipping_addresses.address_line_2 = null;
        this.form.shipping_addresses.city = null;
        this.form.shipping_addresses.state = null;
        this.form.shipping_addresses.post_code = null;
        this.form.shipping_addresses.type = "shipping";
      }
    },
    getCityListByStateId(address_type) {
      this.loading = true;
      let state_id = null;
      if (address_type == "billing") {
        state_id = this.form.state;
      } else if (address_type == "shipping") {
        state_id = this.form.state;
      }
      getCityListByStateId(state_id).then((response) => {
        if (address_type == "billing") {
          this.form.city = null;
          this.city_list = response.data.data;
        } else if (address_type == "shipping") {
          this.form.city = null;
          this.city_list = response.data.data;
        }
        this.loading = false;
      });
    },
    getCityList() {
      this.loading = true;
      getCityList().then((response) => {
        this.city_list = response.data.data;

        this.loading = false;
      });
    },
    getStateList() {
      this.loading = true;
      getStateList().then((response) => {
        this.state_list = response.data.data;
        this.loading = false;
      });
    },
    getStateByStatePrefix(state_prefix) {
      getStateByPrefix(state_prefix).then((response) => {
        this.form.state = response.data.data.state_id;
      });
    },
    getCityIdByName(city_name) {
      getCityByName(city_name).then((response) => {
        const city_id = response.data.data.city_id;
        const stateId = response.data.data.state_id;
        getCityListByStateId(stateId).then((response) => {
          this.form.city = null;
          this.city_list = response.data.data;
          this.form.city = city_id;
          this.city_loading = false;
        });
      });
    },
    getAddressData: function (addressData) {
      console.log(addressData, "addressData");
      
      if(addressData.postal_code != null){
        this.form.post_code = addressData.postal_code;
      }else{
        this.form.post_code = '';
      }

      var city_name = addressData.locality;
      if (city_name != null) {
        this.getCityIdByName(city_name, "0");
      }
      var state_prefix = addressData.administrative_area_level_1;
      if (state_prefix != null) {
        this.getStateByStatePrefix(state_prefix, "0");
      }

      var street = "";
      if (addressData.street_number != null) {
        street = addressData.street_number;
      }

      this.form.address_line_2 = street + " " + addressData.route;     

      // Selvan added this
      const _addressCopy = this.address;
      this.address = addressData;
      this.address.id = _addressCopy.id;
      //

      console.log(this.address, "this.address after");
    },
    reset() {
      this.form.reset();
    },
    closeForm() {
      if (!this.form.submiting) {
        globalEvents.$emit("AddressAdded", false);
        this.$emit("close");
      }
    },
    checkZip(value) {
      return /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(value);
    },
    validPostal(postalcode) {
      this.form.errors.add("post_code", "");
      let postal_code = postalcode.toUpperCase();
      if (this.checkZip(postal_code)) {
        this.form.post_code = postal_code
          .replace(/\W/g, "")
          .replace(/(...)/, "$1 ");
      } else {
        const h = this.$createElement;
        this.$notify({
          message: h(
            "i",
            {
              style: "color: red",
            },
            "Invalid Postal Code"
          ),
          offset: 200,
          type: "warning",
        });
        this.form.post_code = "";
      }
    },
    submitForm() {
      this.loading = true;
      const formURL = this.address.id
        ? "/customer/address/update/" + this.address.id
        : "/customer/address/create/" + this.customer_id;
      const msg = this.address.id
        ? "Address has been updated"
        : "Address has been created";
      this.form
        .post(formURL)
        .then((response) => {
          //console.log(response);
          this.$message({
            message: msg,
            type: "success",
          });
          this.loading = false;
          globalEvents.$emit("AddressAdded", response.data);
          this.$emit("close");
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
  created() {
    let address_id = this.$route.params.address_id;

    if (parseInt(address_id) > 0) {
      this.submit_btn_text = "Update Address";
      this.title_text = "Edit Address";
      this.response_msg = "Address updated successfully.";
    }
    this.getStateList();
    this.getCityList();
  },
  components: {
    VueGoogleAutocomplete,
  },
  mounted() {
    if (this.address.id) {
      console.log(this.address);
      this.form = new Form(this.address);
      this.form.address_line_1 =
        this.address.address_line_1 == null ? "" : this.address.address_line_1;
      this.form.city = this.address.city_details.city_id;
      this.form.state = this.address.state_details.state_id;
    }
  },
};
</script>