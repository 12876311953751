<!-- Employees -->
<template>
<div>
  <div slot="header" class="modal-header popup-header">
    <span class="modal-title-popup"><i class="flaticon-interface-9" aria-hidden="true"></i>{{label.title}}</span>
			<i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
		</div>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Employees-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Employees Body-->
                <div class="row justify-content-center ">
                    <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                         <el-form ref="form" :model="form" auto-complete="nope">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                <div class="row">
                                    
                                            <div class="col-xl-6">
                                               <el-form-item label="Brand" prop="brand_name">
                                                 <el-input v-model="form.brand_name" @input.native="capitalize" auto-complete="nope" :class="form.errors.has('brand_name') ? 'error required':'required'"></el-input>
                                                 <span class="form-err" v-if="form.errors.has('brand_name')" >{{form.errors.get('brand_name')}}</span>
                                              </el-form-item>                                                   
                                            </div>

                                          <div class="col-xl-6">
                                             <el-form-item label="Brand Model" prop="brand_model">
                                                 <el-input v-model="form.brand_model" @input.native="capitalize" auto-complete="nope" ></el-input>                                                 
                                              </el-form-item>                                                 
                                            </div>
                                           
                                            <div class="col-xl-6">
                                          <el-form-item label="Brand Image" prop="brand_image">
                                            <div class="form-group mt-6 mb-0">
                                                  <div class="field text-center">
                                                    <div class="preview-container">
                                                      <img v-if="img_preview" :src="img_preview" class="avatar"/>
                                                    </div>
                                                  </div>
                                                  <div class="d-flex justify-content ml-12" >
                                                    <div class="upload-btn-container">
                                                      <a href="#">
                                                        <span v-if="image_btn == 0" class="el-button el-button--primary el-button--small is-circle"><i class="fa fa-plus text-white"></i></span>                                                   
                                                        <input
                                                          type="file"
                                                          @change="onFileChange"
                                                          accept="image/*"
                                                        />
                                                      </a>
                                                      <el-button @click="removeImage"
                                                      class="ml-5"
                                                      circle
                                                      size="small"
                                                      type="danger"                                                 
                                                    >
                                                      <i class="fa fa-trash text-white"></i>
                                                    </el-button>                                               
                                                </div>
                                              </div>
                                            </div>
                                        </el-form-item> 
                                    </div>  
                                    <div class="col-xl-6">
                                        <el-form-item label="Status" prop="cat_status">
                                        <el-select v-model="form.active" >
                                            <el-option v-for="(option,index) in options" :key="index" :label="option.text" :value="option.id">
                                            </el-option>
                                          </el-select>
                                        </el-form-item>                                               
                                    </div>                             
                                </div>
                            </div>

                            <!--begin: Employees Actions -->
                            <div class="d-flex justify-content-between border-top pt-5">
                                <a @click="createBrand" class="text-white">
                                    <div class="btn btn-primary btn-sm font-weight-bold text-uppercase">
                                        {{submit_btn_text}}
                                    </div>
                                </a>
                            </div>
                            <!--end: Employees Actions -->
                        </el-form>
                        <!--end: Employees Form-->
                    </div>
                </div>
                <!--end: Employees Body-->
            </div>
        </div>
        <!--end: Employees-->
    </div>
</div>
</template>



<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { globalEvents } from '@/utils/globalEvents';
import Form from '@/core/services/api.form.services'
import {getBrand} from "@/api/product";
import {getAllBrands} from "@/api/product";
export default {
  name: 'add-brand',
   props: {
   
    label:{
      type: Object,
      default() {
        return {button:'Create',title:'Create Brand'}
      }
	  }
  },
    data() {
    return {
      loading:false,
      form : new Form({
        brand_name: null,
        brand_model : null,
        brand_image : null,
        active: 1,
      }),
    options: [
            { id: 1, text: "Active" },
            { id: 0, text: "In Active" }
        ],
     
      img_preview: "/./media/bg/profile-img.png",
      image_btn_text: null,
      submit_btn_text: 'Add Brand',
      title_text: 'Create Brand',
      api_url: '/product/add-brand',
      response_msg: 'Brand created successfully.',
      delete_image : false,
      image_btn: 0,
    }
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Product Brands", route: "/product/brands" },
      { title: "Brand" }
    ]);
  },
  methods: {
     closeForm() {
    	if (!this.form.submiting) {
      	globalEvents.$emit('BrandCreated', false)
      	this.$emit('close')
    	}
	  },
    createBrand: function() {
      this.loading=true;
      this.form.post(this.api_url).then((response) => {
        if(response.status){          
            this.loading=false;
            globalEvents.$emit('BrandCreated', response.data)
            this.$emit('close');           
            //this.$showResponse('success', response.message);
        }
      })
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (files.length <= 0) {
        return;
      }
      this.form.brand_image = files[0];
      this.img_preview = URL.createObjectURL(files[0]);
    },
    removeImage(){
      this.form.brand_image = '';
      this.img_preview =  "/./media/bg/profile-img.png";
    },

     getAllBrands(){
      this.loading = true
      getAllBrands().then(response => {
        this.brand_name = response.data.data
        this.loading = false
      })
    },
      
    viewAllBrands(){
      this.$router.push({path:'/product/brands'})
    },
  },

  created(){
    let brand_id = this.$route.params.brand_id;
    this.image_btn_text = 'Add Image'
    if(parseInt(brand_id) > 0){
      this.image_btn_text = 'Update image'
      this.submit_btn_text = 'Update Brand'
      this.title_text = 'Update Brand'
      this.action = 'update'
      this.response_msg = 'Brand updated successfully.'

      getBrand(brand_id).then(response => {        
        this.api_url = 'product/edit-brand/' + response.data.data[0].brand_id   
        this.form = new Form(response.data.data[0])
       this.getAllBrands();
      });
    }
  }

}
</script>
<style scoped>
.upload-btn-container{
  display: flex !important;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>


