<template>
 <div>
   <div slot="header" class="modal-header popup-header">
      <span class="modal-title-popup"><i class="flaticon-interface-9" aria-hidden="true"></i>Record a payment for this invoice</span>
      <i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
   </div>
   <div class="card card-custom" v-loading="loading">
      <div class="card-body p-0">
         <!--begin: Employees-->
         <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
            <!--begin: Employees Body-->
            <div class="row justify-content-center ">
                 <el-row>
                    <el-col :span="4" class="mt-7" style="text-align:end">
                         <span class="pay_icons"><i class="fa fa-credit-card" aria-hidden="true" style="color:blue;"></i></span>
                    </el-col>
                    <el-col :span="14" :offset="2" >
                        <span>Process your Customer's credit Card directly through Wave</span>
                        <el-button type="primary" class="mt-5" round @click="CardPayment()">Charge a credit card</el-button>
                    </el-col>
                    <el-col :span="24" class="separator mt-5 mb-5">or</el-col>
                 </el-row>
              
                <el-row>
                    <el-col :span="4" class="mt-7" style="text-align:end">
                        
                         <span class="pay_icons"><i class="fas fa-hand-holding-usd" style="color:blue"></i></span>
                    </el-col>
                    <el-col :span="14" :offset="2" >
                        <span>Record a Payment you've already received, Such as cash, cheque or bank Payment</span>
                        <el-button type="primary" class="mt-5" round @click="RecordPayment()">Record a manual Payment</el-button>
                    </el-col>
                    
                 </el-row>
            </div>
            <!--end: Employees Body-->
         </div>
      </div>
      <!--end: Employees-->
   </div>
</div>
</template>

<script>

import Form from '@/core/services/api.form.services';
import { globalEvents } from '@/utils/globalEvents';
import {getPaymentMethods} from "@/api/invoice";
import RecoredPayment from '@/components/DirectPayment/recordpayment';
import cardPayment from '@/components/DirectPayment/cardPayment';
import {getActiveCard} from "@/api/payment";
import CreditCard from '@/components/DirectPayment/creditcard';
export default {
  name: 'create-user',
   props: {
    invoice: {
      type: Object
    },
  termCondition:{
    type:Number
  },
  customer_id:{
    type:Number
  },
   Credit: {
      type: Object
    },
    formData:{
      type: Object
    }
  },
  data() {
    return {
      loading:false,
      form: new Form({
        payment_date: new Date(),
        due_amount: null,
        payment_method: null,
        notes: null,
        invoice_id: null,
      }),
      
      payment_methods:[],
      card_details : [],
      api_url: '/invoice/record/payment',
      response_msg: 'Payment has been successfull'
    }
  },
  components: {
  },
  methods: {
    reset() {
      this.form.reset()
      this.$refs['form'].resetFields()
    },
    closeForm() {
      
      if (!this.form.submiting) {
        this.$emit('close')
      }
    },
    RecordPayment(){
          this.$emit('close')
          
          this.$modal.show(RecoredPayment, 
          { invoice:this.invoice,
            termCondition:this.termCondition,
            customer_id:this.customer_id,
            Credit:this.Credit,
            formData:this.formData,
          }, 
          {
                draggable: true,
                    transition: 'pop-out',
                    height: 'auto',
                    clickToClose: true,
                    adaptive: true,
                    width: '450px'
                });
    },
     CardPayment(){
          this.$emit('close')
          if(!this.card_details){
          this.$modal.show(cardPayment, 
          { invoice:this.invoice,
            customer_id:this.customer_id,
            Credit:this.Credit,
            formData:this.formData,
            }, 
          {
                draggable: true,
                    transition: 'pop-out',
                    height: 'auto',
                    clickToClose: true,
                    adaptive: true,
                    width: '450px'
                });
          }else{
             this.$modal.show(CreditCard, 
             {invoice:this.invoice,
             customer_id:this.customer_id,
             card:this.card_details,
             Credit:this.Credit,
             formData:this.formData,
             }, 
             {
                draggable: true,
                    transition: 'pop-out',
                    height: 'auto',
                    clickToClose: true,
                    adaptive: true,
                    width: '450px'
                });

          }
    },
      getActiveCard(){
        this.card_details = [];
      this.loading = true
      getActiveCard(this.invoice.customer_id).then(response => {        
          this.card_details = response.data.data
          console.log(this.card_details);
          this.loading = false
      });
    },
    submitForm() {
      this.loading=true;
      this.form.payment_date = this.$formatDate(this.form.payment_date, 'YYYY-MM-DD');
      this.form.post(this.api_url).then((response) => {
        this.$message({
          message: this.response_msg,
          type: 'success'
        })
        globalEvents.$emit('invoiceAdded', response.data)
        this.$emit('close')
        this.loading=false
      }).catch((error) => { 
        console.log(error);
        this.loading=false
      })
    },
    getPaymentMethods(){
      this.loading = true
      getPaymentMethods().then(response => {
          this.payment_methods = response.data.data
          this.loading = false
      });
    }
  },
  created() { 
    
    this.getPaymentMethods();
    this.getActiveCard();   
    if(this.invoice.id) {
      this.form.invoice_id = this.invoice.id
      this.form.due_amount = this.invoice.balance_amount
    }
  }
}
</script>

<style scoped>
.pay_icons{
    background: lightblue;  
    padding: 20px;
    border-radius: 30px;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}
</style>