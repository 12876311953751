const scrollToMandatoryFields = (error, refs) => {
  var elements = Object.keys(JSON.parse(JSON.stringify(error)));
  if (elements.length > 0) {
    var tags = elements.map(element => refs[element]);
    var positions = tags.map(tag => tag.offsetTop);
    var topValue = Math.min(...positions);
    window.scrollTo(0, topValue);
  }
}

export default scrollToMandatoryFields;
