<!-- Employees -->
<template>
<div v-loading="loading">
  <div slot="header" class="modal-header popup-header">
    <span class="modal-title-popup"><i class="flaticon-layers" aria-hidden="true"></i>{{label.title}}</span>
			<i style="float: right; padding: 3px 0" class="model-close el-icon-close icon-pointer" @click.prevent="closeForm"></i>
		</div>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Employees-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Employees Body-->
                <div class="row ">
                    <div class="col-xl-12">
                        <!--begin: Wizard Form-->
                         <el-form ref="form" :model="form" auto-complete="nope">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">                                
                                <div class="row">
                                    <div class="col-xl-12">                                        
                                        <div class="row">
                                            <div class="col-xl-6">
                                               <el-form-item label="Category Name" prop="cat_name">
                                                 <el-input v-model="form.label" @input.native="capitalize" auto-complete="nope" :class="form.errors.has('label') ? 'error required':'required'"></el-input>
                                                 <span class="form-err" v-if="form.errors.has('label')" >{{form.errors.get('label')}}</span>
                                              </el-form-item>                                               
                                            </div>                                           
                                            <div class="col-xl-6">
                                               <el-form-item label="Status" prop="cat_status">
                                                 <el-select v-model="form.cat_status" >
                                                    <el-option v-for="(option,index) in options" :key="index" :label="option.text" :value="option.id">
                                                    </el-option>
                                                 </el-select>
                                              </el-form-item>                                             
                                            </div>
                                            <div class="col-xl-6">
                                               <el-form-item label="Category Location" prop="cat_name">
                                                <el-select v-model="form.cat_location" :class="form.errors.has('cat_location') ? 'error required':'required'">
                                                    <el-option v-for="(option,index) in locations" :key="index" :label="option.text" :value="option.id">
                                                    </el-option>
                                                 </el-select>
                                                 <span class="form-err" v-if="form.errors.has('cat_location')" >Category Location Field is Required</span>
                                              </el-form-item>                                               
                                            </div> 
                                            <div class="col-xl-6">
                                               <el-form-item label="Dealer Margin(%)" prop="dealer_margin">
                                                 <el-input-number v-model="form.dealer_margin" auto-complete="nope"></el-input-number>
                                              </el-form-item>                                               
                                            </div> 
                                            <div class="col-xl-6">
                                            <el-form-item label="Category Image" prop="cat_name">
                                            <div class="form-group mt-6 mb-0">
                                              <div class="field text-center">
                                                <div class="preview-container">
                                                  <img v-if="img_preview" :src="img_preview" class="avatar"/>
                                                </div>
                                              </div>
                                              <div class="d-flex justify-content ml-10" >
                                                <div class="upload-btn-container">
                                                  <a href="#">
                                                    <span v-if="image_btn == 0" class="el-button el-button--primary el-button--small is-circle"><i class="fa fa-plus text-white"></i></span>                                                   
                                                    <input
                                                      type="file"
                                                      @change="onFileChange"
                                                      accept="image/*"
                                                    />
                                                  </a>
                                                  <el-button @click="removeImage"
                                                  class="ml-5"
                                                  circle
                                                  size="small"
                                                  type="danger"
                                                 
                                                >
                                                  <i class="fa fa-trash text-white"></i>
                                                </el-button>
                                               
                                                </div>
                                              </div>
                                            </div>
                                            </el-form-item>
                                          </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!--begin: Employees Actions -->
                            <div class="d-flex justify-content-center border-top pt-5" v-if="category_action=='add'">
                              <el-button type="primary" @click="createCategory" class="btn btn-sm btn-primary font-weight-bold text-uppercase" :icon="form.submiting?'el-icon-loading':''">                                   
                                      {{submit_btn_text}}
                              </el-button>
                              
                            </div>
                             <div class="d-flex justify-content-center border-top pt-5" v-if="category_action=='edit'">
                              <a @click="editCategory" class="text-white" >
                                    <div class="btn btn-sm btn-primary font-weight-bold text-uppercase">
                                      {{submit_btn_text}}
                                    </div>
                                </a>
                              
                            </div>
                            <!--end: Employees Actions -->
                        </el-form>
                        <!--end: Employees Form-->
                    </div>
                </div>
                <!--end: Employees Body-->
            </div>
        </div>
        <!--end: Employees-->
    </div>
</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Form from '@/core/services/api.form.services'
import { globalEvents } from '@/utils/globalEvents';
export default {
  name: 'add-category',
  props: {
       parentData: {
	      	type: Object,
	      	required: true
	    },
       category_action: {
	      	type: String,
	      	required: true
	    },
  label:{
        type: Object,
        default() {
          return {button:'Create',title:'Create Category'}
        }
      }
  },
  
    data() {
    return {
      loading:false,  
      img_preview: "/./media/bg/profile-img.png",
      form : new Form({
        parent_id: null, 
        label : null,        
        cat_status: 1,  
        category_action : null,  
        category_image : '' ,
        cat_location : null,
        dealer_margin : null,      
      }),
     
      submit_btn_text: 'Add Category',
      title_text: 'Create Category',
      api_url: '/product/add-category',
      response_msg: 'Category created successfully.',
      cat_name: [],
       options: [
            { id: 1, text: "Active" },
            { id: 0, text: "In Active" }
        ],
        locations: [
            { id: 1, text: "Store" },
            { id: 0, text: "Store/Web" }
        ],
        selectedOptionId: 1,
        delete_image : false,
        image_btn: 0,
    }
  },
  components: {},
  mounted() {

     if(this.category_action === 'edit'){  
      this.form.cat_status = this.parentData.cat_status
       this.form.category_action = this.category_action;     
       this.submit_btn_text = 'Update Category'
       this.label.title = 'Update Category'
       this.form.label = this.parentData.label
       this.form.cat_location = this.parentData.cat_location
       this.form.dealer_margin = this.parentData.dealer_margin
       if (this.parentData.category_image) {
        this.form.category_image = this.parentData.category_image;
        this.img_preview =
            process.env.VUE_APP_BASE_IMAGE_URL +
            this.parentData.category_image;
            this.delete_image = true;
        }
       this.api_url = 'product/edit-category/' + this.parentData.id
      
    }
      if(this.category_action === 'add'){ 
     if(this.parentData.id != undefined)
      this.form.parent_id = this.parentData.id;
    }
         
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Product Categories", route: "/product/categories" },
      { title: "Category" }
    ]);
  },
  methods: {
     closeForm() {
    	if (!this.form.submiting) {
      	globalEvents.$emit('CategoryCreated', false)
      	this.$emit('close')
    	}
	  },
    createCategory: function(){    
      this.loading=true;
      this.form.post(this.api_url).then((response) => {
        if(response.status){            
            this.loading=false;
            globalEvents.$emit('CategoryCreated', response.data)
            this.$emit('close');
            this.$showResponse('success', response.message);
        }
      }).catch(() => {
                this.loading = false;
            })
      
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (files.length <= 0) {
        return;
      }

      this.form.category_image = files[0];

      this.img_preview = URL.createObjectURL(files[0]);
    },
    removeImage(){
      this.form.category_image = '';
      this.img_preview =  "/./media/bg/profile-img.png";
    },
      editCategory(){

        this.loading=true;
       this.form.post(this.api_url).then((response) => {
        if(response.status){   
            this.$emit('close');         
            this.loading=false;
            globalEvents.$emit('CategoryCreated', response.data)
            this.$showResponse('success', response.message);
        }
      }).catch(() => {
                this.loading = false;
            })
     
      },
     
  },
  created(){
  
  }

}
</script>
<style>
.upload-btn-container{
  display: flex !important;
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>

