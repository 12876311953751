import request from '@/core/services/api.services.request'

export function getActiveCard(customer_id) {
    return request({
      url: '/active/card/'+customer_id,
      method: 'get',
    })
  }


  export function checkPayment(payment_password) {
    return request({
      url: '/payment/password/'+payment_password,
      method: 'get',
    })
  }
  